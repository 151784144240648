import React from 'react';

import { CustomTable } from '../../components';
import * as helpers from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.RequestPage;

const findLabel = (Arr: any[], categ: string) => {
  return Arr.find(x => x.value === categ)
    ? Arr.find(x => x.value === categ).label
    : '';
};

const columnsTable = [
  {
    Header: 'RÉFÉRENCE',
    accessor: 'eventNbr',
    width: 120
  },
  {
    Header: 'CATÉGORIE',
    accessor: 'eventCategory',
    Cell: (props: any) => {
      return findLabel(Wording.categories, props.value);
    }
  },
  {
    Header: 'SOUS-CATÉGORIE',
    accessor: 'eventSubcategory',
    Cell: (props: any) => {
      return findLabel(Wording.subCategories, props.value);
    }
  },
  {
    Header: 'DATE',
    accessor: 'referenceDate',
    Cell: (props: any) => {
      return helpers.getFormattedDate(props.value);
    },
    width: 125
  },
  {
    Header: 'STATUT',
    accessor: 'eventStatusCode',
    Cell: (props: any) => {
      return findLabel(Wording.statusEvent, props.value);
    },
    width: 160
  }
];

const EventContainer: React.FC<{
  data: object[];
}> = ({ data }) => {
  return (
    <div className="request-container">
      <h2 className="request-container__sub-title">{Wording.subTitle}</h2>

      <CustomTable
        columns={columnsTable}
        data={data}
        defaultPageSize={data.length > 10 ? 10 : data.length}
        noDataText={Wording.noDataText}
        sortable={true}
        className="-striped -highlight"
        SubComponen={(row: any) => {
          return (
            <div className="sub-component">
              {row.original.message}
              <span className="line" />
            </div>
          );
        }}
      />
    </div>
  );
};

export default EventContainer;
