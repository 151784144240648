import React from 'react';

import { TAddress, TWordingItem } from 'Models';
import { IContact, IBilling } from './types';
import { ECivility, ECustomerType } from '../../utils/enums';
import { getLabelCivility } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.ProfilePage;

const array_pro: TWordingItem[] = [
  {
    id: '1',
    label: Wording.pro.companyName,
    value: 'companyName'
  },
  {
    id: '2',
    label: Wording.pro.companyLegalFormCode,
    value: 'companyType'
  },
  {
    id: '3',
    label: Wording.pro.activityCode,
    value: 'activityCode'
  },
  {
    id: '4',
    label: Wording.pro.identificationNumber,
    value: 'identificationNumber'
  }
];

const CustomerContact: React.FC<{
  contact: IContact;
  billing: IBilling;
  contractNumber: string;
  type: string;
  customerType?: string;
}> = ({ contact, billing, contractNumber, type, customerType }) => {
  const renderProData = (cnt: any) => {
    return array_pro.map((pro: TWordingItem, index: number) => (
      <React.Fragment key={index}>
        {cnt[pro.value] && (
          <p className="margin">
            {pro.label} {cnt[pro.value]}
          </p>
        )}
      </React.Fragment>
    ));
  };
  let c: {
    civility: ECivility;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    birthdate?: string;
    address: TAddress;
  } = {
    civility: contact.civility,
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
    phone: contact.phone,
    birthdate: contact.birthdate,
    address: contact.address
  };
  if (type === 'billing') {
    c = {
      civility: billing.civility,
      firstName: billing.firstName,
      lastName: billing.lastName,
      email: billing.email,
      address: billing.address
    };
  }

  return (
    <div className="profile-container__information">
      <div className="profile-container__information__consumption">
        {type === 'contact' ? (
          <h3>{Wording.consumption.title}</h3>
        ) : (
          <h3>{Wording.billing.title}</h3>
        )}
        {c.firstName && (
          <p>{`${getLabelCivility(c.civility)} ${c.firstName} ${
            c.lastName
          }`}</p>
        )}
        {type === 'contact' ? (
          <p className="margin">{Wording.consumption.email}</p>
        ) : (
          <p className="margin">{Wording.billing.email}</p>
        )}
        {c.email && <p>{c.email}</p>}
        {c.phone && (
          <p className="margin">
            {Wording.phone} {c.phone}
          </p>
        )}
        {c.birthdate !== '' && c.birthdate !== undefined && (
          <p className="margin">Date de naissance : {c.birthdate}</p>
        )}
        {customerType === ECustomerType.PROFESSIONAL && renderProData(contact)}
        {customerType === ECustomerType.INDIVIDUAL &&
          contact.coholderCivility &&
          contact.coholderFirstName &&
          contact.coholderFirstName !== '' && (
            <p className="margin">
              Co-titulaire : {getLabelCivility(contact.coholderCivility)}{' '}
              {contact.coholderFirstName}
            </p>
          )}
      </div>
      <div className="profile-container__information__address">
        {type === 'contact' ? (
          <h3>{Wording.consumption.addressTitle}</h3>
        ) : (
          <h3>{Wording.billing.contact.address}</h3>
        )}
        {contractNumber && (
          <p className="contract">{`Contrat n° ${contractNumber}`}</p>
        )}
        <p className="street">
          {c.address.number} {c.address.street}
        </p>
        <p className="townName">
          {c.address.postalCode} {c.address.townName}
        </p>
      </div>
    </div>
  );
};

export default CustomerContact;
