export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';
export const REDUX_RESET_STATE = 'REDUX_RESET_STATE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const FETCH_CONTEXT_REQUEST = 'FETCH_CONTEXT_REQUEST';
export const FETCH_CONTEXT_SUCCESS = 'FETCH_CONTEXT_SUCCESS';
export const FETCH_CONTEXT_FAILURE = 'FETCH_CONTEXT_FAILURE';

export const FETCH_CONTRACT_REQUEST = 'FETCH_CONTRACT_REQUEST';
export const FETCH_CONTRACT_SUCCESS = 'FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACT_FAILURE = 'FETCH_CONTRACT_FAILURE';
export const RESET_CONTRACT_STATE = 'RESET_CONTRACT_STATE';

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';
export const CREATE_EVENT_RESET = 'CREATE_EVENT_RESET';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER_RESET = 'UPDATE_CUSTOMER_RESET';

export const UPDATE_BILLING_REQUEST = 'UPDATE_BILLING_REQUEST';
export const UPDATE_BILLING_SUCCESS = 'UPDATE_BILLING_SUCCESS';
export const UPDATE_BILLING_FAILURE = 'UPDATE_BILLING_FAILURE';

export const FETCH_METER_CONFIG_REQUEST = 'FETCH_METER_CONFIG_REQUEST';
export const FETCH_METER_CONFIG_SUCCESS = 'FETCH_METER_CONFIG_SUCCESS';
export const FETCH_METER_CONFIG_FAILURE = 'FETCH_METER_CONFIG_FAILURE';
export const FETCH_METER_READING_REQUEST = 'FETCH_METER_READING_REQUEST';
export const FETCH_METER_READING_SUCCESS = 'FETCH_METER_READING_SUCCESS';
export const FETCH_METER_READING_FAILURE = 'FETCH_METER_READING_FAILURE';

export const CREATE_INDEX_REQUEST = 'CREATE_INDEX_REQUEST';
export const CREATE_INDEX_SUCCESS = 'CREATE_INDEX_SUCCESS';
export const CREATE_INDEX_FAILURE = 'CREATE_INDEX_FAILURE';
export const CREATE_INDEX_RESET = 'CREATE_INDEX_RESET';

export const CREATE_SPONSOR_REQUEST = 'CREATE_SPONSOR_REQUEST';
export const CREATE_SPONSOR_SUCCESS = 'CREATE_SPONSOR_SUCCESS';
export const CREATE_SPONSOR_FAILURE = 'CREATE_SPONSOR_FAILURE';

export const FETCH_LIST_FILES_REQUEST = 'FETCH_LIST_FILES_REQUEST';
export const FETCH_LIST_FILES_SUCCESS = 'FETCH_LIST_FILES_SUCCESS';
export const FETCH_LIST_FILES_FAILURE = 'FETCH_LIST_FILES_FAILURE';

export const FETCH_PIECES_REQUEST = 'FETCH_PIECES_REQUEST';
export const FETCH_PIECES_SUCCESS = 'FETCH_PIECES_SUCCESS';
export const FETCH_PIECES_FAILURE = 'FETCH_PIECES_FAILURE';

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';
export const CREATE_PAYMENT_RESET = 'CREATE_PAYMENT_RESET';

export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE';
export const FETCH_PACKAGE_REQUEST = 'FETCH_PACKAGE_REQUEST';
export const FETCH_PACKAGE_SUCCESS = 'FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAILURE = 'FETCH_PACKAGE_FAILURE';
export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';
export const FETCH_OFFER_REQUEST = 'FETCH_OFFER_REQUEST';
export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const FETCH_OFFER_FAILURE = 'FETCH_OFFER_FAILURE';
export const UPDATE_PACKAGE_REQUEST = 'UPDATE_PACKAGE_REQUEST';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILURE = 'UPDATE_PACKAGE_FAILURE';
export const UPDATE_PACKAGE_RESET = 'UPDATE_PACKAGE_RESET';
