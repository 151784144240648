import { Epic } from 'redux-observable';
import { of, forkJoin, throwError, Observable } from 'rxjs';
import { mergeMap, switchMap, catchError, filter } from 'rxjs/operators';
import {
  createAsyncAction,
  createStandardAction,
  isActionOf,
  ActionType
} from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';
import { TMeterRead, TResponse } from 'Models';
import { ERateOption } from '../utils/enums';

import {
  FETCH_METER_CONFIG_REQUEST,
  FETCH_METER_CONFIG_SUCCESS,
  FETCH_METER_CONFIG_FAILURE,
  FETCH_METER_READING_REQUEST,
  FETCH_METER_READING_SUCCESS,
  FETCH_METER_READING_FAILURE,
  CREATE_INDEX_REQUEST,
  CREATE_INDEX_SUCCESS,
  CREATE_INDEX_FAILURE,
  CREATE_INDEX_RESET
} from './actionTypes';

interface IPayload {
  contractNbr: string;
  pointOfDelivery: string;
  readingDate?: string;
  energyType?: string;
  meterReads?: Array<{
    meterNumber: string;
    registerName: string;
    timeframeCode: string;
    value: string;
  }>;
}

export interface IMeterConfigResponse {
  rateOption: ERateOption;
  digitSize: number;
  meterNumber: string;
  error?: string;
  code?: string;
  message?: string;
}

const resetCreateIndex = createStandardAction(CREATE_INDEX_RESET)();

const fetchMeterConfigAsync = createAsyncAction(
  FETCH_METER_CONFIG_REQUEST,
  FETCH_METER_CONFIG_SUCCESS,
  FETCH_METER_CONFIG_FAILURE
)<IPayload, IMeterConfigResponse, IMeterConfigResponse>();

const fetchMeterReadingAsync = createAsyncAction(
  FETCH_METER_READING_REQUEST,
  FETCH_METER_READING_SUCCESS,
  FETCH_METER_READING_FAILURE
)<IPayload, TMeterRead[], TResponse>();

const createIndexAsync = createAsyncAction(
  CREATE_INDEX_REQUEST,
  CREATE_INDEX_SUCCESS,
  CREATE_INDEX_FAILURE
)<IPayload, string, TResponse>();

export type ConsumptionAction =
  | ActionType<typeof fetchMeterConfigAsync>
  | ActionType<typeof fetchMeterReadingAsync>
  | ActionType<typeof createIndexAsync>
  | ActionType<typeof resetCreateIndex>;

const preparePayloadMeterReading = ({
  contractNbr,
  pointOfDelivery,
  energyType
}: IPayload) => {
  return {
    contractNbr,
    pointOfDelivery,
    energyType
  };
};

const preparePayloadMeterConfig = ({
  contractNbr,
  pointOfDelivery,
  readingDate
}: IPayload) => {
  return {
    contractNbr,
    pointOfDelivery,
    readingDate
  };
};

const mapGetMeterReading = (
  action: RootAction,
  { apiRequest }: Services
): Observable<{
  reads: TMeterRead[];
  error?: string;
  code?: string;
  message?: string;
}> => {
  const payload = preparePayloadMeterReading(action.payload);
  return apiRequest<TMeterRead[]>({
    path: '/getMeterReads',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: TMeterRead[]) => {
      if (response) {
        return of({
          reads: response
        });
      }
      const message = 'Une erreur est survenue';
      return throwError({ code: '200', message });
    }),
    catchError(error => {
      return of({
        reads: [],
        error: error.message,
        code: error.code,
        message: error.message
      });
    })
  );
};

const mapGetMeterConfig = (
  action: RootAction,
  { apiRequest }: Services
): Observable<IMeterConfigResponse | TResponse> => {
  const payload = preparePayloadMeterConfig(action.payload);
  return apiRequest<IMeterConfigResponse>({
    path: '/getMeterConfiguration',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: IMeterConfigResponse) => {
      if (response) {
        return of({
          ...response,
          code: '200'
        });
      }
      const message = 'Une erreur est survenue';
      return throwError({ code: '200', message });
    }),
    catchError(error => {
      return of({
        error: error.message,
        code: error.code,
        message: error.message
      });
    })
  );
};

const fetchMeterEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(fetchMeterConfigAsync.request)),
    switchMap(
      (action: RootAction) => mapGetMeterConfig(action, dependency),
      (action: RootAction, r: IMeterConfigResponse | TResponse) => [action, r]
    ),
    switchMap(([action, response]) => {
      if (response.code !== '200') {
        throwError({ code: response.code, message: response.message });
      }
      return forkJoin(of(response), mapGetMeterReading(action, dependency));
    }),
    switchMap(([meterConfigResponse, meterReadingResponse]) => {
      let meterConfig;
      let meterReading;

      if (meterConfigResponse.code !== '200') {
        meterConfig = fetchMeterConfigAsync.failure(meterConfigResponse);
      } else {
        meterConfig = fetchMeterConfigAsync.success(meterConfigResponse);
      }
      if (meterReadingResponse.code && meterReadingResponse.message) {
        meterReading = fetchMeterReadingAsync.failure({
          code: meterReadingResponse.code,
          message: meterReadingResponse.message
        });
      } else {
        meterReading = fetchMeterReadingAsync.success(
          meterReadingResponse.reads
        );
      }
      return of(meterConfig, meterReading);
    }),
    catchError(error => of(fetchMeterConfigAsync.failure(error)))
  );

const preparePayloadIndex = ({
  contractNbr,
  pointOfDelivery,
  readingDate,
  meterReads
}: IPayload) => {
  return {
    contractNbr,
    pointOfDelivery,
    readingDate,
    meterReads
  };
};

const mapPostIndex = (action: RootAction, { apiRequest }: Services) => {
  const payload = preparePayloadIndex(action.payload);
  return apiRequest<string>({
    path: '/createIndex',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: string) => {
      return of(createIndexAsync.success('success'));
    }),
    catchError(error => {
      return of(createIndexAsync.failure(error));
    })
  );
};

const createIndexEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(createIndexAsync.request)),
    mergeMap(action => mapPostIndex(action, dependency))
  );

export {
  fetchMeterConfigAsync,
  fetchMeterReadingAsync,
  fetchMeterEpic,
  resetCreateIndex,
  createIndexAsync,
  createIndexEpic
};
