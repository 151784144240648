import React, { useState } from 'react';

import { InputEvent } from 'Types';
import { TPackage, TPrepaymentFrequency } from 'Models';
import { EFrequency, EModeManageOffer } from '../../utils/enums';
import { CustomButton } from '../../components';
import FrequencyContainer from '../FrequencyContainer';
import GaugeContainer from '../GaugeContainer';
import { IPayloadUpdatePackage as PackagePayload } from '../../_actions/package.actions';

import WordingConstant from '../../utils/wording.json';
import { findPackagesByRange, findPackageById } from '../../utils/helpers';

const Wording = WordingConstant.PackagePage;

/**
 * Props current contains 4 params to be only displayed by default
 */
type Props = {
  current: {
    frequency: EFrequency;
    packageID: string;
    prices: number[];
  };
  loading: boolean;
  packages?: TPackage[];
  ranges: number[][];
  prepaymentFrequencies?: TPrepaymentFrequency[];
  handleFetchPackage(): void;
  handleSetShowOption(showOption: boolean): void;
  handleValidate(section: string, payload: PackagePayload): void;
};

const PackageSettingContainer: React.FC<Props> = ({
  current,
  loading,
  packages,
  ranges,
  prepaymentFrequencies,
  handleSetShowOption,
  handleFetchPackage,
  handleValidate
}) => {
  const [showFrequency, setShowFrequency] = useState<boolean>(false);
  const [showGauge, setShowGauge] = useState<boolean>(false);
  const [showGaugeTab, setShowGaugeTab] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<string>(current.frequency);
  const [packageID, setPackageID] = useState<string>(current.packageID);
  const [prices, setPrices] = useState<number[]>(current.prices);

  if (!showGauge && !showFrequency) {
    return (
      <div className="package-container__body__right__button">
        <CustomButton
          title={Wording.plan.gauge.buttonTitle}
          color="orange"
          onClick={() => {
            setShowGauge(true);
            setPackageID(current.packageID);
            handleSetShowOption(false);
            if (packages === undefined || packages.length === 0) {
              handleFetchPackage();
            }
          }}
        />
        <CustomButton
          title={Wording.plan.frequency.buttonTitle}
          color="orange"
          onClick={() => {
            setShowFrequency(true);
            setFrequency(current.frequency);
            handleSetShowOption(false);
            if (
              prepaymentFrequencies === undefined ||
              prepaymentFrequencies.length === 0
            ) {
              handleFetchPackage();
            }
          }}
        />
      </div>
    );
  }

  const handleValidateClick = () => {
    let section = '';
    let payload: PackagePayload = {
      mode: EModeManageOffer.INSTALLMENT_FREQUENCY
    };
    if (showFrequency) {
      section = 'frequency';
      // The frequency is currently chosen from state
      payload = {
        frequency: frequency as EFrequency,
        mode: EModeManageOffer.INSTALLMENT_FREQUENCY
      };
    } else if (showGaugeTab) {
      section = 'prices';
      // The price ranges are based on current chosenPackage
      // If the winter-summer prices are already chosen and no packageID found
      // choose the recommended package
      const currentPackage = findPackageById(current.packageID, packages, true);
      const authorizedPackages = currentPackage
        ? currentPackage.authorizedPackages
        : undefined;
      const chosenPackages = findPackagesByRange(prices, authorizedPackages);
      payload = {
        chosenPackages,
        mode: EModeManageOffer.PACKAGE
      };
    } else {
      section = 'packageID';
      // The packageID is currently chosen from state
      const currentPackage = findPackageById(packageID, packages, false);
      const chosenPackages = currentPackage ? [currentPackage] : undefined;
      payload = {
        chosenPackages,
        mode: EModeManageOffer.PACKAGE
      };
    }
    handleValidate(section, payload);
  };

  return (
    <div className="package-container__body__right__setting">
      {showFrequency ? (
        <FrequencyContainer
          loading={loading}
          chosenFrequency={frequency}
          prepaymentFrequencies={prepaymentFrequencies || []}
          onChange={(freq: InputEvent) => {
            setFrequency(freq.currentTarget.value);
          }}
        />
      ) : (
        <GaugeContainer
          loading={loading}
          packages={packages}
          ranges={ranges}
          prices={prices}
          packageID={packageID}
          showGaugeTab={showGaugeTab}
          handleToggleGauge={gauge => {
            setShowGaugeTab(gauge);
            if (gauge && current.prices.length < 2) {
              setPrices(ranges[0]);
            }
          }}
          handleChangePackageID={(value: string) => setPackageID(value)}
          handleChangePrices={(value: number[]) => setPrices(value)}
        />
      )}

      <div className="package-container__body__right__setting__button">
        <CustomButton
          title={Wording.plan.gauge.cancelButton}
          color="gray"
          onClick={() => {
            setShowGauge(false);
            setShowFrequency(false);
            handleSetShowOption(true);
          }}
        />
        <CustomButton
          title={Wording.plan.gauge.validateButton}
          color="orange"
          onClick={() => {
            setShowGauge(false);
            setShowFrequency(false);
            handleSetShowOption(true);
            handleValidateClick();
          }}
        />
      </div>
    </div>
  );
};

export default PackageSettingContainer;
