import React from 'react';

import { CustomButton, CustomDayPicker } from '../../components';
import { TPiece } from 'Models';
import { initialPieces } from '../../utils/initialState';
import {
  getFormattedDate,
  getFormattedDateToBackEnd
} from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.FailPieceContainer;

type Props = {
  piece: TPiece;
  handleChangeFildPieces: ({
    pieceId,
    executionDate,
    pieceDate,
    pieceType
  }: {
    pieceId: string;
    pieceDate: string;
    pieceType: string;
    executionDate: string;
  }) => void;
  handleShowModal(section: string): void;
};

type State = {
  isCalendarOpen: boolean;
  piece: TPiece;
  selectedDay: Date | undefined;
};

const DISABLED = [
  { before: new Date(new Date().setDate(new Date().getDate() + 1)) },
  {
    daysOfWeek: [0, 6]
  }
];

class FailPieceContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { piece } = this.props;
    this.state = {
      isCalendarOpen: false,
      piece: piece || initialPieces,
      selectedDay: undefined
    };
  }

  isWeekend = (date: Date) => {
    return date.getDay() === 6 || date.getDay() === 0;
  };

  handleClick = (condition: boolean) => {
    this.setState({ isCalendarOpen: condition });
  };

  handleClickValid = () => {
    this.setState({ isCalendarOpen: false });
  };

  handlChangeDay = (date: Date) => {
    const { handleChangeFildPieces } = this.props;
    const { piece } = this.state;
    if (date.getTime() > new Date().getTime() && !this.isWeekend(date)) {
      this.setState({
        selectedDay: date
      });
    }

    const dataToState: {
      pieceId: string;
      pieceDate: string;
      pieceType: string;
      executionDate: string;
    } = {
      pieceId: piece.pieceId,
      pieceType: piece.pieceType,
      pieceDate: piece.pieceDueDate,
      executionDate: getFormattedDateToBackEnd(date)
    };

    handleChangeFildPieces(dataToState);
  };

  render() {
    const { isCalendarOpen, piece, selectedDay } = this.state;
    const { handleShowModal } = this.props;

    return (
      <div className="fail-piece-container">
        <div className="fail-piece-container__title">
          <span className="fail-piece-container__title__exclamation-point">
            !
          </span>
          <p>
            Le prélèvement du {getFormattedDate(piece.pieceDueDate)} d’un
            montant de {piece.pieceAmount}€ à été refusé
          </p>
        </div>

        <p>{Wording.title2}</p>

        {(isCalendarOpen && (
          <div className="fail-piece-container__calendar">
            <CustomDayPicker
              dateHighlighted={selectedDay}
              handlChangeDay={this.handlChangeDay}
              disabledDays={DISABLED}
            />

            <CustomButton
              color="gray"
              title={Wording.buttonClose}
              onClick={() => this.setState({ isCalendarOpen: false })}
            />
            <CustomButton
              color={selectedDay !== undefined ? 'orange' : 'gray'}
              title={Wording.buttonSave}
              onClick={
                selectedDay !== undefined ? () => handleShowModal('') : null
              }
            />
          </div>
        )) || (
          <CustomButton
            color="orange"
            title={Wording.buttonChoice}
            onClick={() => this.handleClick(true)}
          />
        )}
      </div>
    );
  }
}

export default FailPieceContainer;
