import React from 'react';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Common;

type Props = {
  show?: boolean;
  showCloseButton?: boolean;
  handleClick?: any;
  children: JSX.Element[] | JSX.Element;
};

const CustomModal: React.FC<Props> = ({
  show,
  showCloseButton,
  handleClick,
  children
}) => (
  <div
    role="button"
    tabIndex={0}
    className={`custom-modal${show ? ' custom-modal--show-modal' : ''}`}
    onClick={handleClick}
    onKeyDown={handleClick}
  >
    <div role="button" tabIndex={0} className="custom-modal__content">
      {showCloseButton && (
        <span
          className="custom-modal__content__close-button"
          onClick={handleClick}
          role="button"
          tabIndex={0}
        >
          {Wording.popup.closeButton}
        </span>
      )}
      {children}
    </div>
  </div>
);

export default CustomModal;
