import * as React from 'react';

interface IProps {
  text?: string;
  value?: string;
  checked?: boolean;
  id?: any;
  handleCheck?: any;
  isLocked?: boolean;
}

const CustomToggle: React.SFC<IProps> = props => (
  <div className="custom-toggle">
    <input
      type="checkbox"
      id={props.id}
      value={props.value}
      checked={props.checked}
      onChange={props.handleCheck}
    />
    <div className="custom-toggle__toggle">
      <label htmlFor={props.id}>
        <i />
      </label>
    </div>
    <p className={` ${props.isLocked && 'isLocked'}`}>{props.text}</p>
  </div>
);

export default CustomToggle;
