import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';
import { TPiece } from 'Models';

import { fetchContractAsync } from '../../_actions/contract.actions';
import { fetchPiecesAsync } from '../../_actions/payment.actions';

import BillContainer from '../../containers/BillContainer';
import withBanner from '../../libraries/withBanner';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.BillPage;

const mapStateToProps = (state: RootState) => ({
  profileState: state.profileReducer,
  contractState: state.contractReducer,
  paymentState: state.paymentReducer
});

const dispatchProps = {
  contractDispatch: fetchContractAsync.request,
  fetchPiecesDispatch: fetchPiecesAsync.request
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WrappedBill = withBanner()(BillContainer);

class BillPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      contractState: { selectedContractNumber },
      fetchPiecesDispatch,
      profileState: { customerNumber }
    }: Props = this.props;

    fetchPiecesDispatch({
      contractNbr: selectedContractNumber,
      customerNbr: customerNumber
    });
  }

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber },
      fetchPiecesDispatch
    } = this.props;
    contractDispatch({
      customerNbr: customerNumber,
      contractNbr: contractNumber
    });
    fetchPiecesDispatch({
      contractNbr: contractNumber,
      customerNbr: customerNumber
    });
  };

  handleDownloadBill = async (key: string) => {
    // const blob = await fetchApiRequestBlob({ key }, '/api....');
    // if (blob) {
    //     const fileURL = URL.createObjectURL(blob);
    //     window.open(fileURL);
    // }
    // console.log('call api for bill and send key ==>', key);
  };

  render() {
    const {
      profileState: { contact, customerType },
      contractState: { contracts, selectedContractNumber },
      paymentState: { pieces }
    }: Props = this.props;

    const bills = pieces.filter(
      (obj: TPiece) => obj.pieceType === Wording.searchTypePiece
    );
    return (
      <WrappedBill
        data={bills}
        handleDownloadBill={this.handleDownloadBill}
        // Banner
        title={Wording.title}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        selectedContractNumber={selectedContractNumber}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(BillPage);
