import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { TFile } from 'Models';

import * as actions from '../_actions/file.actions';

export type FileState = Readonly<{
  files: TFile[];
}>;

export const initialState = {
  files: []
};

type Actions = actions.FileActions;

const fileReducer: Reducer<FileState, Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getType(actions.fetchFilesListAsync.request):
      return { ...state };

    case getType(actions.fetchFilesListAsync.failure):
      return { ...state };

    case getType(actions.fetchFilesListAsync.success): {
      return {
        ...state,
        files: action.payload
      };
    }

    default:
      return { ...state };
  }
};

export default fileReducer;
