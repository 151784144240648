import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';

import { RootState, InputEvent } from 'Types';
import withBanner, {
  ExternalProps as ExternalPropsBanner
} from '../../libraries/withBanner';
import withModal, {
  ExternalProps as ExternalPropsModal,
  InjectedProps as InjectedPropsModal
} from '../../libraries/withModal';
import ParentingContainer, {
  Props as ContainerProps
} from '../../containers/SponsorContainer/SponsorContainer';

import { fetchContractAsync } from '../../_actions/contract.actions';
import { isValidEmail } from '../../utils/helpers';
import {
  createSponsorAsync,
  resetUpdate
} from '../../_actions/profile.actions';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ParentingPage;

const mapStateToProps = (state: RootState) => ({
  profileState: state.profileReducer,
  contractState: state.contractReducer
});

const dispatchProps = {
  contractDispatch: fetchContractAsync.request,
  createSponsorDispatch: createSponsorAsync.request,
  resetDispatch: resetUpdate
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WrappedParenting = compose<
  ContainerProps,
  Subtract<ContainerProps, InjectedPropsModal> &
    ExternalPropsBanner &
    ExternalPropsModal
>(
  withBanner(),
  withModal()
)(ParentingContainer);

type State = {
  fields: {
    email: string;
    error?: string;
    [key: string]: string | undefined;
  };
};

class SponsorPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fields: {
        email: '',
        error: undefined
      }
    };
  }

  handleInputChange = (e: InputEvent, type: string) => {
    const { fields } = this.state;
    fields[type] = e.currentTarget.value;
    this.setState({ fields });
  };

  handleInputError = (): boolean => {
    const { fields } = this.state;

    if (isValidEmail(fields.email)) {
      fields.error = undefined;
      this.setState({ fields });
      return true;
    }
    fields.error = Wording.errorInput;
    this.setState({ fields });
    return false;
  };

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber }
    } = this.props;
    contractDispatch({
      customerNbr: customerNumber,
      contractNbr: contractNumber
    });
  };

  handleUpdate = () => {
    const { fields } = this.state;
    const {
      profileState: {
        customerNumber,
        contact: {
          customerFullName: { firstName, lastName }
        }
      },
      createSponsorDispatch
    } = this.props;
    createSponsorDispatch({
      sponsorId: customerNumber,
      sponsorFirstName: firstName,
      sponsorLastName: lastName,
      targetEmail: fields.email
    });
    fields.email = '';
    this.setState({ fields });
  };

  render() {
    const { fields } = this.state;
    const {
      profileState: { contact, updateResponse, customerType },
      contractState: { contracts, selectedContractNumber },
      resetDispatch
    }: Props = this.props;
    return (
      <WrappedParenting
        fields={fields}
        handleInputChange={this.handleInputChange}
        handleInputError={this.handleInputError}
        // Banner
        title={Wording.title}
        selectedContractNumber={selectedContractNumber}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        // Modal
        modalMessage={Wording.popup}
        updateResponse={updateResponse}
        handleValidate={this.handleUpdate}
        handleReset={resetDispatch}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(SponsorPage);
