import React from 'react';
import Lottie from 'react-lottie';

import { CustomModal, Modal } from '../components';
import animationData from '../assets/loader.json';
import WordingConstant from '../utils/wording.json';

const CommonWording = WordingConstant.Common;

export const LoadingSpinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData
  };
  return (
    <div className="loader">
      <CustomModal show>
        <Lottie
          options={defaultOptions}
          height={180}
          width={180}
          isStopped={false}
        />
      </CustomModal>
    </div>
  );
};

export type ExternalProps = {
  loading: boolean;
  unauthorized: boolean;
  handleCloseModal: () => void;
};

type Options = {
  debug?: boolean;
};

const withLoader = ({ debug = false }: Options = {}) => <OwnProps extends {}>(
  WrappedComponent: React.ComponentType<OwnProps>
) => {
  type HocProps = OwnProps & ExternalProps;

  const component: React.FC<HocProps> = ({
    loading,
    unauthorized,
    handleCloseModal,
    ...props
  }) => {
    return (
      <div className="loader-page">
        {loading && <LoadingSpinner />}

        <Modal
          show={unauthorized}
          title={CommonWording.expiredSession.title}
          description={CommonWording.expiredSession.description}
          state={1}
          handleCancel={handleCloseModal}
        />

        <WrappedComponent {...props as OwnProps} />
      </div>
    );
  };
  return component;
};

export default withLoader;
