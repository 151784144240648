import React from 'react';

import { TFile } from 'Models';
import { CustomTable, CustomButton } from '../../components';

import { initialFile } from '../../utils/initialState';
import { getLabelByValue } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.FilePage;

type Props = {
  data: TFile[];
  isLoading: boolean;
  handleDownloadProofOfResidence(): void;
  handleDownloadFile(key: string, name: string): Promise<void>;
};

const FileContainer: React.FC<Props> = props => {
  const searchNameFile = (key: string): string => {
    const obj =
      props.data.find((e: TFile) => e.documentKey === key) || initialFile;

    return getLabelByValue(obj.documentName, Wording.fileNameMapping);
  };

  const columnsTable = [
    { Header: 'DATE', accessor: 'documentDate', width: 150 },
    {
      Header: 'TYPE',
      accessor: 'documentName',
      Cell: (propsCell: any) =>
        getLabelByValue(propsCell.value, Wording.fileNameMapping) || 'Document',

      width: 650
    },
    {
      Header: 'TÉLÉCHARGER',
      accessor: 'documentKey',
      Cell: (propsCell: any) => {
        const name = searchNameFile(propsCell.value);
        return (
          <a
            href={`#telechargement:${name
              .toLocaleLowerCase()
              .replace(' ', '_')}`}
            onClick={() => props.handleDownloadFile(propsCell.value, name)}
          >
            Fichier
          </a>
        );
      },
      width: 200
    }
  ];

  return (
    <div className="file-container">
      <h2 className="file-container__file">{Wording.file}</h2>
      <CustomTable
        columns={columnsTable}
        data={props.data}
        defaultPageSize={props.data && props.data.length}
        noDataText={Wording.noDataText}
        sortable={false}
      />

      <p className="file-container__paragraph">
        {Wording.description.generalCondition}{' '}
        <a
          href={WordingConstant.Common.uppli}
          target="_blank"
          rel="noopener noreferrer"
        >
          {Wording.description.uppliLink}
        </a>
        {Wording.description.section}
        <b>
          {' '}
          <a href={Wording.CGVLink} target="_blank" rel="noopener noreferrer">
            {Wording.description.CGV}
          </a>
        </b>
      </p>
      <CustomButton
        color="orange"
        title={Wording.buttons.ProofOfResidence}
        isLoading={props.isLoading}
        onClick={!props.isLoading ? props.handleDownloadProofOfResidence : null}
      />
    </div>
  );
};

export default FileContainer;
