import React from 'react';

import HomePage from './HomePage';
import ApplicationFormPage from './FormPage';
import BillPage from './BillPage';
import FilePage from './FilePage';
import PaymentPage from './PaymentPage';
import ProfilePage from './ProfilePage';
import SponsorPage from './SponsorPage';
import EventPage from './EventPage';
import MovePage from './MovePage';
import PackagePage from './PackagePage';
import SettingPage from './SettingPage';
import ConsumptionPage from './ConsumptionPage';

import WordingConstant from '../utils/wording.json';
const WordingRoutes = WordingConstant.MainPage.SideBar.routes;

export type RouteProps = {
  title: string;
  path: string;
  links: SubRouteProps[];
};

export type SubRouteProps = {
  path: string;
  title: string;
  main: React.ComponentType;
  exact?: boolean;
  sidebar: React.ComponentType;
};

export default [
  {
    title: WordingRoutes.home.title,
    path: WordingRoutes.home.path,
    links: [
      {
        path: WordingRoutes.home.links.path,
        exact: true,
        sidebar: () => <div>Mon dashboard</div>,
        main: () => <HomePage />,
        title: WordingRoutes.home.links.title
      }
    ]
  },
  {
    title: WordingRoutes.consumption.title,
    path: WordingRoutes.consumption.path,
    links: [
      {
        path: WordingRoutes.consumption.links.history.path,
        sidebar: () => <div>Historique de ma consommation</div>,
        main: () => <ConsumptionPage />,
        title: WordingRoutes.consumption.links.history.title
      },
      {
        path: WordingRoutes.consumption.links.lastMonth.path,
        sidebar: () => <div>Consommation du mois passé</div>,
        main: () => <ConsumptionPage />,
        title: WordingRoutes.consumption.links.lastMonth.title
      },
      {
        path: WordingRoutes.consumption.links.selfRelief.path,
        sidebar: () => <div>Mon auto-relève</div>,
        main: () => <ConsumptionPage />,
        title: WordingRoutes.consumption.links.selfRelief.title
      }
    ]
  },
  {
    title: WordingRoutes.offer.title,
    path: WordingRoutes.offer.path,
    links: [
      {
        path: WordingRoutes.offer.links.package.path,
        sidebar: () => <div>Mon forfait</div>,
        main: () => <PackagePage />,
        title: WordingRoutes.offer.links.package.title
      },
      {
        path: WordingRoutes.offer.links.options.path,
        sidebar: () => <div>Mes options</div>,
        main: () => <PackagePage />,
        title: WordingRoutes.offer.links.options.title
      },
      {
        path: WordingRoutes.offer.links.changePackege.path,
        sidebar: () => <div>Changer de forfait</div>,
        main: () => <PackagePage />,
        title: WordingRoutes.offer.links.changePackege.title
      }
    ]
  },
  {
    title: WordingRoutes.profile.title,
    path: WordingRoutes.profile.path,
    links: [
      {
        path: WordingRoutes.profile.links.information.path,
        sidebar: () => <div>Informations personnelles et bancaires</div>,
        main: () => <ProfilePage />,
        title: WordingRoutes.profile.links.information.title
      },
      {
        path: WordingRoutes.profile.links.setting.path,
        sidebar: () => <div>Paramètres</div>,
        main: () => <SettingPage />,
        title: WordingRoutes.profile.links.setting.title
      },
      {
        path: WordingRoutes.profile.links.sponsorship.path,
        sidebar: () => <div>Parrainage</div>,
        main: () => <SponsorPage />,
        title: WordingRoutes.profile.links.sponsorship.title
      },
      {
        path: WordingRoutes.profile.links.move.path,
        sidebar: () => <div>Je déménage</div>,
        main: () => <MovePage />,
        title: WordingRoutes.profile.links.move.title
      }
    ]
  },
  {
    title: WordingRoutes.document.title,
    path: WordingRoutes.document.path,
    links: [
      {
        path: WordingRoutes.document.links.files.path,
        sidebar: () => <div>Mes fichiers</div>,
        main: () => <FilePage />,
        title: WordingRoutes.document.links.files.title
      },
      {
        path: WordingRoutes.document.links.bills.path,
        sidebar: () => <div>Mes factures</div>,
        main: () => <BillPage />,
        title: WordingRoutes.document.links.bills.title
      },
      {
        path: WordingRoutes.document.links.payments.path,
        sidebar: () => <div>Mes paiements</div>,
        main: () => <PaymentPage />,
        title: WordingRoutes.document.links.payments.title
      }
    ]
  },
  {
    title: WordingRoutes.contact.title,
    path: WordingRoutes.contact.path,
    links: [
      {
        path: WordingRoutes.contact.links.applicationForms.path,
        sidebar: () => <div>Formulaires de demandes</div>,
        main: () => <ApplicationFormPage />,
        title: WordingRoutes.contact.links.applicationForms.title
      },
      {
        path: WordingRoutes.contact.links.requests.path,
        sidebar: () => <div>Mes demandes</div>,
        main: () => <EventPage />,
        title: WordingRoutes.contact.links.requests.title
      },
      {
        path: WordingRoutes.contact.links.FAQ.path,
        title: WordingRoutes.contact.links.FAQ.title,
        sidebar: () => <div>FAQ</div>,
        main: () => <div />
      }
    ]
  }
];
