import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';

import { RootState, InputEvent } from 'Types';
import { ERateOption } from '../../utils/enums';

import withBanner, {
  ExternalProps as ExternalPropsBanner
} from '../../libraries/withBanner';
import withModal, {
  ExternalProps as ExternalPropsModal,
  InjectedProps as InjectedPropsModal
} from '../../libraries/withModal';
import ConsumptionContainer, {
  Props as ContainerProps
} from '../../containers/ConsumptionContainer/ConsumptionContainer';
import { InputItem } from '../../components/CustomTransfer/CustomTransfer';

import {
  fetchContractAsync,
  resetContractState
} from '../../_actions/contract.actions';
import {
  fetchMeterConfigAsync,
  createIndexAsync,
  resetCreateIndex
} from '../../_actions/consumption.actions';
import {
  displayPlaceholder,
  getTodayDate,
  findContractByNumber,
  getTimeframeFromLabel
} from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.ConsumptionPage;

const mapStateToProps = (state: RootState) => ({
  profileState: state.profileReducer,
  contractState: state.contractReducer,
  consumptionState: state.consumptionReducer
});

const dispatchProps = {
  contractDispatch: fetchContractAsync.request,
  fetchMeterDispatch: fetchMeterConfigAsync.request,
  createIndexDispatch: createIndexAsync.request,
  resetContractDispatch: resetContractState,
  resetCreateIndexDispatch: resetCreateIndex
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WrappedConsumption = compose<
  ContainerProps,
  Subtract<ContainerProps, InjectedPropsModal> &
    ExternalPropsBanner &
    ExternalPropsModal
>(
  withBanner(),
  withModal()
)(ConsumptionContainer);

type State = {
  fields: {
    inputs: InputItem[];
  };
};

class ConsumptionPage extends React.Component<Props, State> {
  state = {
    fields: {
      inputs: [
        {
          label: '',
          value: '',
          maxLength: 0,
          onChange: (e: InputEvent) => {}
        }
      ]
    }
  };

  componentDidMount() {
    const {
      contractState: { selectedContractNumber, contracts },
      fetchMeterDispatch
    } = this.props;
    const {
      consumptionState: { rateOption }
    } = this.props;
    if (rateOption !== ERateOption.UNKNOWN) {
      this.setInitialState();
    }
    if (selectedContractNumber === '') {
      return;
    }
    const contract = findContractByNumber(selectedContractNumber, contracts);
    if (contract) {
      fetchMeterDispatch({
        contractNbr: selectedContractNumber,
        pointOfDelivery: contract.deliveryPoint.pointOfDelivery,
        readingDate: getTodayDate(),
        energyType: contract.energy
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // Typical usage (don't forget to compare props):
    const {
      fetchMeterDispatch,
      contractState: { contracts, selectedContractNumber },
      consumptionState: { rateOption }
    } = this.props;

    if (
      prevState.fields.inputs[0].label === '' &&
      rateOption !== prevProps.consumptionState.rateOption
    ) {
      this.setInitialState();
    }
    const { contractState } = prevProps;
    if (selectedContractNumber !== contractState.selectedContractNumber) {
      const contract = findContractByNumber(selectedContractNumber, contracts);
      if (contract) {
        fetchMeterDispatch({
          contractNbr: selectedContractNumber,
          pointOfDelivery: contract.deliveryPoint.pointOfDelivery,
          readingDate: getTodayDate(),
          energyType: contract.energy
        });
      }
    }
  }

  setInitialState = () => {
    const { fields } = this.state;
    const {
      consumptionState: { rateOption, digitSize }
    } = this.props;

    let list: Array<{ label: string; placeholder?: string }> = [];
    if (rateOption === ERateOption.HIGH_LOW) {
      list = Wording.autoTransfer.input.highlow;
    } else if (rateOption === ERateOption.TOTAL_HOUR) {
      list = Wording.autoTransfer.input.totalhour;
    } else {
      list = [
        {
          label: ''
        }
      ];
    }
    const inputs = list.map((input, index) => {
      const onChange = (e: InputEvent) => this.handleInputChange(e, index);
      const placeholder = displayPlaceholder(digitSize);
      return {
        ...input,
        onChange,
        placeholder,
        value: '',
        maxLength: digitSize
      };
    });
    fields.inputs = inputs;
    this.setState({ fields });
  };

  handleInputChange = (e: InputEvent, idx: number) => {
    const { fields } = this.state;
    fields.inputs[idx].value = e.currentTarget.value.replace(/[^\d]/, '');
    this.setState({ fields });
  };

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber }
    } = this.props;
    contractDispatch({
      customerNbr: customerNumber,
      contractNbr: contractNumber
    });
  };

  handleUpdate = (section: string) => {
    const {
      contractState: { selectedContractNumber, contracts },
      consumptionState: { meterNumber },
      createIndexDispatch
    } = this.props;
    if (selectedContractNumber === '') {
      return;
    }
    const contract = findContractByNumber(selectedContractNumber, contracts);
    if (contract) {
      const { fields } = this.state;
      const meterReads = fields.inputs.map(input => {
        return {
          meterNumber,
          registerName: '',
          value: input.value,
          timeframeCode: getTimeframeFromLabel(input.label)
        };
      });
      createIndexDispatch({
        meterReads,
        contractNbr: selectedContractNumber,
        pointOfDelivery: contract.deliveryPoint.pointOfDelivery,
        readingDate: getTodayDate()
      });
    }
  };

  getMeters = () => {
    const {
      consumptionState: { meters }
    } = this.props;
    if (!meters) return [];
    const data: Array<{
      meterType: string;
      date: string;
      index: number;
      consumption: number;
      timeframe: string;
    }> = [];
    meters.forEach(m => {
      if (m.meterReads) {
        m.meterReads.forEach(r => {
          data.push({
            meterType: m.meterReadType,
            date: m.meterReadDate,
            index: r.index,
            consumption: r.consumption,
            timeframe: r.timeframe
          });
        });
      }
    });
    return data;
  };

  handleInputError = (): boolean => {
    const { fields } = this.state;
    const reducer = (acc: boolean, current: InputItem) =>
      acc && current.value.length === current.maxLength;
    const condition = fields.inputs.reduce(reducer, true);
    if (!condition) {
      fields.inputs = fields.inputs.map(input => {
        const error =
          input.value.length !== input.maxLength
            ? `Veuillez saisir les ${input.maxLength} derniers chiffres`
            : undefined;
        return {
          ...input,
          error
        };
      });
      this.setState({ fields });
      return false;
    }
    fields.inputs = fields.inputs.map(input => {
      return {
        ...input,
        error: undefined
      };
    });
    this.setState({ fields });
    return true;
  };

  render() {
    const {
      profileState: { contact, customerType },
      contractState: { contracts, selectedContractNumber, error },
      consumptionState: { updateResponse, errorMeterConfig, errorMeterReading },
      resetCreateIndexDispatch,
      resetContractDispatch
    }: Props = this.props;

    const {
      fields: { inputs }
    } = this.state;

    const currentEnergy = findContractByNumber(
      selectedContractNumber,
      contracts
    ).energy;

    return (
      <WrappedConsumption
        data={this.getMeters()}
        inputs={inputs}
        handleInputChange={this.handleInputChange}
        handleInputError={this.handleInputError}
        errorMeterConfig={errorMeterConfig}
        errorMeterReading={errorMeterReading}
        currentEnergy={currentEnergy}
        // Banner
        title={Wording.title}
        selectedContractNumber={selectedContractNumber}
        bannerError={error}
        handleCloseModalBanner={() => resetContractDispatch()}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        // Modal
        modalMessage={Wording.popup}
        updateResponse={updateResponse}
        handleValidate={this.handleUpdate}
        handleReset={resetCreateIndexDispatch}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(ConsumptionPage);
