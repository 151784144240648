import React, { useState } from 'react';
import { InputEvent } from 'Types';
import { TContract } from 'Models';
import { IFields, ISetting, IContact, IBilling } from './types';
import { ECustomerType } from '../../utils/enums';

import CustomerContact from './CustomerContact';
import Payment from './Payment';
import ModificationCoholder from './ModificationCoholder';
import ModificationPayment from './ModificationPayment';
import ModificationContact from './ModificationContact';
import ModificationBilling from './ModificationBilling';
import ModificationButtons from './ModificationButtons';

import {
  isValidEmail,
  isValidPhone,
  isValidIban,
  isValidString,
  isValidName,
  displayPhoneWithoutPrefix
} from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

export type Props = {
  selectedContractNumber: string;
  contracts: TContract[];
  customerType: string;
  setting: ISetting;
  fields: IFields;
  currentContact: IContact;
  billing: IBilling;
  handleChangeDropdown?: any;
  handleAddressChange?: any;
  onFocusDropdown?: any;
  handleResetCoholder(civility?: string, firstName?: string): void;
  handleInputChange(e: InputEvent, section: string, type: string): void;
  handleChangeCivility(section: string, value: string): void;
  handleShowErrorMessage(section: string): void;
  handleShowModal(section: string): void;
};

/**
 * Display the only container for profile
 * @param currentContact is fixed data containing contact info
 * @param billing is fixed data containing billing info
 * @param fields is variable data containing contact info and billing info
 * @param setting is variable data containing error and address info
 */
const ProfileContainer: React.FC<Props> = ({
  selectedContractNumber,
  contracts,
  customerType,
  currentContact,
  billing,
  fields,
  setting,
  handleChangeDropdown,
  onFocusDropdown,
  handleAddressChange,
  handleResetCoholder,
  handleShowModal,
  handleShowErrorMessage,
  handleInputChange,
  handleChangeCivility
}) => {
  const [checkUpdateContact, setCheckUpdateContact] = useState<boolean>(false);
  const [checkUpdateBilling, setCheckUpdateBilling] = useState<boolean>(false);
  const [checkUpdateIban, setCheckUpdateIban] = useState<boolean>(false);

  const handleValidateContact = (): boolean => {
    const {
      contact: { email, phone, coholderCivility, coholderFirstName }
    } = fields;
    const coholderCondition =
      coholderCivility && coholderFirstName
        ? isValidName(coholderFirstName)
        : !setting.checkCoholder;
    return (
      isValidEmail(email) &&
      isValidPhone(displayPhoneWithoutPrefix(phone)) &&
      coholderCondition
    );
  };

  const handleValidateBilling = (): boolean => {
    const {
      email,
      firstName,
      lastName,
      address: { postalCode, street, townName }
    } = fields.billing;
    return (
      isValidString(firstName) &&
      isValidString(lastName) &&
      isValidEmail(email) &&
      postalCode !== '' &&
      street !== '' &&
      townName !== ''
    );
  };

  const handleValidateIban = (): boolean => {
    const {
      billing: { iban }
    } = fields;
    return isValidIban(iban);
  };

  const contract =
    contracts.find(
      (c: TContract) => c.contractNumber === selectedContractNumber
    ) || contracts[0];

  const isValidContact = handleValidateContact();
  const isValidBilling = handleValidateBilling();
  const isValidIbanCode = handleValidateIban();

  return (
    <div className="profile-container">
      <h1 className="profile-container__subtitle">{Wording.subtitle}</h1>

      {!checkUpdateContact ? (
        <CustomerContact
          type="contact"
          contractNumber={contract.contractNumber}
          contact={currentContact}
          billing={billing}
          customerType={customerType}
        />
      ) : (
        <>
          <ModificationContact
            fields={fields}
            handleInputChange={handleInputChange}
            showError={setting.showErrorCustomer}
          />
          {customerType === ECustomerType.INDIVIDUAL && (
            <ModificationCoholder
              fields={fields}
              setting={setting}
              showError={setting.showErrorCustomer}
              handleChangeCivility={handleChangeCivility}
              handleInputChange={handleInputChange}
              handleResetCoholder={handleResetCoholder}
            />
          )}
        </>
      )}

      <ModificationButtons
        showButton={!checkUpdateContact}
        condition={isValidContact}
        handleButtonClick={() => setCheckUpdateContact(true)}
        handleButtonCancel={() => setCheckUpdateContact(false)}
        handleButtonValidate={
          isValidContact
            ? () => handleShowModal('contact')
            : () => handleShowErrorMessage('contact')
        }
      />

      <span className="profile-container__line" />

      {!checkUpdateBilling ? (
        <React.Fragment>
          <CustomerContact
            type="billing"
            contact={currentContact}
            billing={billing}
            contractNumber={contract.contractNumber}
          />
        </React.Fragment>
      ) : (
        <ModificationBilling
          fields={fields}
          setting={setting}
          handleChangeCivility={handleChangeCivility}
          handleChangeDropdown={handleChangeDropdown}
          handleAddressChange={handleAddressChange}
          handleInputChange={handleInputChange}
          onFocusDropdown={onFocusDropdown}
        />
      )}

      <ModificationButtons
        showButton={!checkUpdateBilling}
        condition={isValidBilling}
        handleButtonClick={() => setCheckUpdateBilling(true)}
        handleButtonCancel={() => setCheckUpdateBilling(false)}
        handleButtonValidate={
          isValidBilling
            ? () => handleShowModal('billing')
            : () => handleShowErrorMessage('billing')
        }
      />

      <span className="profile-container__line" />

      {!checkUpdateIban ? (
        <Payment finance={contract.finance} />
      ) : (
        <ModificationPayment
          fields={fields}
          showError={setting.showErrorIban}
          handleInputChange={handleInputChange}
        />
      )}

      <ModificationButtons
        showButton={!checkUpdateIban}
        condition={isValidIbanCode}
        handleButtonClick={() => setCheckUpdateIban(true)}
        handleButtonCancel={() => setCheckUpdateIban(false)}
        handleButtonValidate={
          isValidIbanCode
            ? () => handleShowModal('iban')
            : () => handleShowErrorMessage('iban')
        }
      />
    </div>
  );
};

export default ProfileContainer;
