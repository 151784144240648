import React from 'react';

import { InputEvent } from 'Types';
import {
  CustomTable,
  CustomTransfer,
  CustomProgressbar
} from '../../components';
import { InputItem } from '../../components/CustomTransfer/CustomTransfer';
import resources from '../../utils/resources';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.ConsumptionPage;

const columns = [
  { Header: 'DATE', accessor: 'date', width: 150 },
  { Header: 'TYPE DE RELÈVE', accessor: 'meterType', width: 300 },
  {
    Header: 'INDEX DE FIN',
    accessor: 'index',
    Cell: (props: any) => <span>{props.value}</span>,
    width: 200
  },
  {
    Header: 'CONSOMMATION',
    accessor: 'consumption',
    Cell: (props: any) => <span>{props.value}</span>,
    width: 200
  },
  {
    Header: 'TYPE DE CADRAN',
    accessor: 'timeframe',
    Cell: (props: any) => <span>{props.value}</span>,
    width: 200
  }
];

export type Props = {
  data: object[];
  inputs: InputItem[];
  errorMeterConfig?: string;
  errorMeterReading?: string;
  currentEnergy: string;
  handleInputError(): boolean;
  handleInputChange(e: InputEvent, index: number): void;
  handleShowModal(section: string): void;
};

const ConsumptionContainer: React.FC<Props> = ({
  data,
  inputs,
  errorMeterConfig,
  errorMeterReading,
  handleInputError,
  handleInputChange,
  handleShowModal,
  currentEnergy
}) => {
  const description = `${Wording.autoTransfer.description1} ${
    inputs[0].maxLength
  }  ${Wording.autoTransfer.description2}`;

  const energyPath =
    currentEnergy === 'EL' ? resources['ic-elec-on'] : resources['ic-gas-on'];
  return (
    <div className="consumption-container">
      {/* <h3>{Wording.progressbarText}</h3> */}
      {/* <CustomProgressbar steps={100} progress={73} pathImg={energyPath} /> */}

      <CustomTransfer
        title={Wording.autoTransfer.title}
        description={description}
        buttonTitle={Wording.autoTransfer.buttonTitle}
        inputs={inputs}
        handleInputChange={handleInputChange}
        handleButtonClick={() => {
          if (handleInputError()) {
            handleShowModal('');
          }
        }}
        error={errorMeterConfig}
      />
      <CustomTable
        columns={columns}
        data={data}
        defaultPageSize={data.length}
        sortable={false}
        title={Wording.history.title}
        buttonTitle={Wording.history.buttonTitle}
        noDataText={Wording.noDataText}
        error={errorMeterReading}
      />
    </div>
  );
};

export default ConsumptionContainer;
