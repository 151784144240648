import { Epic } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
  createAsyncAction,
  createStandardAction,
  isActionOf,
  ActionType
} from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';
import { TEvent, TResponse } from 'Models';

import {
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE,
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_RESET
} from './actionTypes';

interface IPayload {
  customerNbr: string;
  contractNbr: string;
}

export interface ICreateEventPayload {
  customerNbr: string;
  contractNbr: string;
  date: string;
  category: string;
  subCategory: string;
  message: string;
}

export interface ICreateEventResponse {
  eventNbr: string;
  code: string;
  message: string;
}

const resetUpdate = createStandardAction(CREATE_EVENT_RESET)();

const createEventAsync = createAsyncAction(
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE
)<ICreateEventPayload, TEvent, TResponse>();

const fetchEventAsync = createAsyncAction(
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE
)<IPayload, TEvent[], TResponse>();

export type EventAction =
  | ActionType<typeof fetchEventAsync>
  | ActionType<typeof createEventAsync>
  | ActionType<typeof resetUpdate>;

const preparePayload = ({
  contractNbr,
  customerNbr
}: {
  contractNbr: string;
  customerNbr: string;
}) => {
  return {
    contractNbr,
    customerNbr
  };
};

const prepareCreateEventPayload = ({
  customerNbr,
  contractNbr,
  date,
  category,
  subCategory,
  message
}: ICreateEventPayload) => {
  return {
    customerNbr,
    contractNbr,
    date,
    category,
    subCategory,
    message
  };
};

const mapGetEvents = (action: RootAction, { apiRequest }: Services) => {
  const payload = preparePayload(action.payload);
  return apiRequest<TEvent[] & { errorMessage: string }>({
    path: '/getEvent',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: TEvent[] & { errorMessage: string }) => {
      if (response) {
        // Check timeout always
        if (response.errorMessage) {
          return throwError({
            code: '200',
            message: response.errorMessage
          });
        }
        return of(fetchEventAsync.success(response));
      }
      return throwError({ code: '200', message: response });
    }),
    catchError(error => of(fetchEventAsync.failure(error)))
  );
};

const fetchEventEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(fetchEventAsync.request)),
    mergeMap(action => mapGetEvents(action, dependency))
  );

const mapCreateEvent = (action: RootAction, { apiRequest }: Services) => {
  const payload = prepareCreateEventPayload(action.payload);
  return apiRequest<ICreateEventResponse & { errorMessage: string }>({
    path: '/createEvent',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: ICreateEventResponse & { errorMessage: string }) => {
      if (response) {
        // Check timeout always
        if (response.errorMessage) {
          return throwError({
            code: '200',
            message: response.errorMessage
          });
        }
        const prepareToStore = {
          eventNbr: response.eventNbr,
          eventCategory: payload.category,
          eventSubcategory: payload.subCategory,
          referenceDate: payload.date,
          eventStatusCode: 'NEW',
          message: payload.message
        };
        return of(createEventAsync.success(prepareToStore));
      }
      return throwError({ code: '200', message: response });
    }),
    catchError(error => of(fetchEventAsync.failure(error)))
  );
};

const createEventEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(createEventAsync.request)),
    mergeMap(action => mapCreateEvent(action, dependency))
  );

export {
  fetchEventAsync,
  fetchEventEpic,
  mapGetEvents,
  createEventAsync,
  createEventEpic,
  resetUpdate,
  mapCreateEvent
};
