import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';

import FileContainer from '../../containers/FileContainer';
import withBanner from '../../libraries/withBanner';

import {
  fetchContractAsync,
  resetContractState
} from '../../_actions/contract.actions';
import { fetchFilesListAsync } from '../../_actions/file.actions';

import { initialCustomerFullName } from '../../utils/initialState';

import { fetchApiRequestBlob } from '../../services/api-service';
import { findContractByNumber } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.FilePage;

const mapStateToProps = (state: RootState) => ({
  profileState: state.profileReducer,
  contractState: state.contractReducer,
  fileState: state.fileReducer
});

const dispatchProps = {
  contractDispatch: fetchContractAsync.request,
  filesListDispatch: fetchFilesListAsync.request,
  resetContractDispatch: resetContractState
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const WrappedFile = withBanner()(FileContainer);

type State = {
  isLoading: boolean;
};

export class FilePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    const {
      contractState: { selectedContractNumber },
      filesListDispatch,
      profileState: { customerNumber }
    }: Props = this.props;

    filesListDispatch({
      contractNbr: selectedContractNumber,
      customerNbr: customerNumber
    });
  }

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber },
      filesListDispatch
    } = this.props;
    const payload = {
      customerNbr: customerNumber,
      contractNbr: contractNumber
    };
    contractDispatch(payload);
    filesListDispatch(payload);
  };

  handleDownloadFile = (key: string, name: string): Promise<void> =>
    this.responseFetchPDF(
      {
        documentKey: key
      },
      '/getDocument',
      name
    );

  handleDownloadProofOfResidence = (): void => {
    const {
      contractState: { selectedContractNumber, contracts },
      profileState: {
        contact: { customerFullName, address }
      }
    } = this.props;

    const contract = findContractByNumber(selectedContractNumber, contracts);
    const thirdParties = contract.thirdParties;
    const coholderName = {
      civility: 'MR',
      firstName: ''
    };
    if (thirdParties) {
      const coholder = thirdParties.find(p => p.role === 'COHOLDER');
      if (coholder && coholder.civility && coholder.firstName) {
        coholderName.civility = coholder.civility;
        coholderName.firstName = coholder.firstName;
      }
    }
    const payload = {
      coholderName,
      contractNbr: selectedContractNumber,
      cunstomerName: customerFullName || { ...initialCustomerFullName },
      energyType: contract.energy,
      subscriptionDate: contract.subscriptionDate,
      residenceAdress: {
        number: address.number,
        street: address.street,
        netArea: address.netArea,
        postalCode: address.postalCode,
        townName: address.townName,
        country: address.country
      }
    };

    this.responseFetchPDF(
      payload,
      '/getProofOfResidence',
      'Attestation de domicile'
    );
    this.setState({ isLoading: true });
  };

  responseFetchPDF = async (payload: any, path: string, pdfName: string) => {
    const blob = await fetchApiRequestBlob(payload, path);
    if (blob) {
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const {
      profileState: { contact, customerType },
      contractState: { contracts, selectedContractNumber, error },
      fileState: { files },
      resetContractDispatch
    }: Props = this.props;

    const { isLoading } = this.state;
    return (
      <WrappedFile
        data={files}
        isLoading={isLoading}
        handleDownloadProofOfResidence={this.handleDownloadProofOfResidence}
        handleDownloadFile={this.handleDownloadFile}
        // Banner
        title={Wording.title}
        bannerError={error}
        handleCloseModalBanner={() => resetContractDispatch()}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        selectedContractNumber={selectedContractNumber}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(FilePage);
