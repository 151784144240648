import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';
import AuthService from '../../services/auth-service';

const Wording = WordingConstant.MainPage.SideBar;

export type InjectedProps = {
  handleLogout(): void;
};

type Props = InjectedProps & RouteComponentProps<any>;

export const LogoutButton: React.FC<Props> = ({ history, handleLogout }) => (
  <div
    className="side-bar__logout"
    onClick={() => {
      AuthService.logout();
      handleLogout();
      history.push('/login');
    }}
  >
    <img src={resources['ic-logout-sidebar']} alt="ic-logout" />
    <p>{Wording.logout}</p>
  </div>
);

export default withRouter(LogoutButton);
