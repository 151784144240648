import React from 'react';

import { CustomButton } from '../../components';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.MovePage;

type Props = {
  handleClick: any;
};

const MoveContainer: React.FC<Props> = props => {
  return (
    <div className="move-container">
      <h1 className="move-container__title">{Wording.title}</h1>
      <h1 className="move-container__sub-title">{Wording.subTitle}</h1>

      <p className="move-container__content">{Wording.content}</p>

      <CustomButton
        onClick={() => props.handleClick(Wording.URL)}
        color="orange"
        title={Wording.buttonValue}
      />
    </div>
  );
};

export default MoveContainer;
