import React from 'react';

const CustomTextarea: React.FC<{
  isLocked?: boolean;
  id: string;
  text: string;
  value: string;
  error?: string;
  onChange: any;
  minLength?: number;
  maxLength?: number;
  defaultValue?: any;
  placeholder?: string;
}> = ({
  id,
  text,
  value,
  minLength,
  maxLength,
  onChange,
  placeholder,
  isLocked,
  defaultValue,
  error
}) => (
  <div className="custom-textarea">
    {text && <label htmlFor={id}>{text}</label>}

    <textarea
      className={`custom-textarea__textarea ${isLocked &&
        ' custom-textarea__textarea--locked'}`}
      minLength={minLength}
      maxLength={maxLength}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
    {error && error !== '' && <p className="custom-textarea__error">{error}</p>}
  </div>
);

export default CustomTextarea;
