import React from 'react';

import { TPiece } from 'Models';

import FailPieceContainer from '../FailPieceContainer';
import PaymentStatusContainer from '../PaymentStatusContainer';

import { getLabelByValue, getFormattedDate } from '../../utils/helpers';
import { CustomTable, Balance } from '../../components';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PaymentPage;

export type Props = {
  pieces: TPiece[];
  balance?: {
    customerBalance: string;
    contractBalance: string;
    invoiceBalance: string;
  };
  fields: {
    pieceId: string;
    pieceDate: string;
    pieceType: string;
    executionDate: string;
  };
  handleBannerChange?: any;
  handleChangeFildPieces: ({
    pieceId,
    executionDate,
    pieceDate,
    pieceType
  }: {
    pieceId: string;
    pieceDate: string;
    pieceType: string;
    executionDate: string;
  }) => void;
  handleShowModal(section: string): void;
};

export const prepareFaildPieces = (pieces: TPiece[]) =>
  pieces.filter((p: TPiece) => p.pieceStatus === Wording.searchFaildStatus);

export const renderPiecesFaild = (
  piecesFaild: TPiece[],
  handleChangeFildPieces: any,
  handleShowModal: any
) => {
  if (piecesFaild && piecesFaild.length !== 0) {
    return piecesFaild.map((p: TPiece, index: number) => {
      return (
        <FailPieceContainer
          key={index}
          piece={p}
          handleChangeFildPieces={handleChangeFildPieces}
          handleShowModal={handleShowModal}
        />
      );
    });
  }
};

const PaymentContainer: React.FC<Props> = props => {
  const columnsTable = [
    {
      Header: 'DATE',
      accessor: 'pieceDate',
      Cell: (propsCell: any) => getFormattedDate(propsCell.value),
      width: 150
    },
    {
      Header: 'TYPE',
      accessor: 'pieceType',
      Cell: (propsCell: any) =>
        getLabelByValue(propsCell.value, Wording.typePieces),
      width: 150
    },
    {
      Header: `DATE D'ECHEANCE`,
      accessor: 'pieceDueDate',
      Cell: (propsCell: any) => getFormattedDate(propsCell.value),

      width: 180
    },
    {
      Header: 'MONTANT',
      accessor: 'pieceAmount',
      Cell: (propsCell: any) => <span>{propsCell.value} €</span>,
      width: 150
    },
    {
      Header: 'SOLDE',
      accessor: 'pieceBalance',
      width: 250
    },
    {
      Header: 'STATUT',
      accessor: 'pieceStatus',
      Cell: (propsCell: any) =>
        getLabelByValue(propsCell.value, Wording.pieceStatus),
      width: 200
    }
  ];
  const faildPieces = prepareFaildPieces(props.pieces);
  return (
    <div className="payment-container">
      <h2>{Wording.balanceTitle}</h2>
      <Balance balance={props.balance} />

      <h2> {Wording.title} </h2>
      <CustomTable
        columns={columnsTable}
        data={props.pieces}
        defaultPageSize={props.pieces && props.pieces.length}
        sortable={false}
        noDataText={Wording.noDataText}
      />

      {props.pieces.length !== 0 && (
        <PaymentStatusContainer pieces={props.pieces} />
      )}

      {faildPieces && faildPieces.length !== 0 && (
        <h2>{Wording.incidentPaiementText}</h2>
      )}

      {renderPiecesFaild(
        faildPieces,
        props.handleChangeFildPieces,
        props.handleShowModal
      )}
    </div>
  );
};

export default PaymentContainer;
