import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';

import { RootState, InputEvent } from 'Types';
import { TWordingItem } from 'Models';

import withBanner, {
  ExternalProps as ExternalPropsBanner
} from '../../libraries/withBanner';
import withModal, {
  ExternalProps as ExternalPropsModal,
  InjectedProps as InjectedPropsModal
} from '../../libraries/withModal';
import FormContainer, {
  Props as ContainerProps
} from '../../containers/FormContainer/FormContainer';
import { createEventAsync, resetUpdate } from '../../_actions/event.actions';
import { fetchContractAsync } from '../../_actions/contract.actions';
import { getTodayDate } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.FormPage;

const mapStateToProps = (state: RootState) => ({
  profileState: state.profileReducer,
  contractState: state.contractReducer,
  eventState: state.eventReducer
});

const dispatchProps = {
  createEventDispatch: createEventAsync.request,
  contractDispatch: fetchContractAsync.request,
  resetCreateEventDispatch: resetUpdate
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const WrappedForm = compose<
  ContainerProps,
  Subtract<ContainerProps, InjectedPropsModal> &
    ExternalPropsBanner &
    ExternalPropsModal
>(
  withBanner(),
  withModal()
)(FormContainer);

type State = {
  fields: {
    category: string;
    subCategory: string;
    message: string;
    [key: string]: any;
  };
};

export class FormPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: {
        category: '',
        subCategory: '',
        message: ''
      }
    };
  }

  handleChangeSelect = (item: TWordingItem, type: string) => {
    const { fields } = this.state;
    fields[type] = item.value;
    if (type === 'category') {
      fields.subCategory = '';
    }
    this.setState({ fields });
  };

  handleChangeTextarea = (e: InputEvent) => {
    const { fields } = this.state;
    fields.message = e.currentTarget.value;
    this.setState({ fields });
  };

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber }
    } = this.props;
    contractDispatch({
      customerNbr: customerNumber,
      contractNbr: contractNumber
    });
  };

  handleUpdate = (section: string) => {
    const { fields } = this.state;
    const {
      profileState,
      contractState,
      createEventDispatch
    }: Props = this.props;

    createEventDispatch({
      contractNbr: contractState.selectedContractNumber,
      customerNbr: profileState.customerNumber,
      date: getTodayDate(),
      category: fields.category,
      subCategory: fields.subCategory,
      message: fields.message
    });

    fields.subCategory = '';
    fields.category = '';
    fields.message = '';
    this.setState({ fields });
  };

  render() {
    const { fields } = this.state;
    const {
      profileState: { contact, customerType },
      contractState: { contracts, selectedContractNumber },
      eventState: { updateResponse },
      resetCreateEventDispatch
    }: Props = this.props;
    return (
      <WrappedForm
        fields={fields}
        handleChangeSelect={this.handleChangeSelect}
        handleChangeTextarea={this.handleChangeTextarea}
        // Banner
        title={Wording.title}
        selectedContractNumber={selectedContractNumber}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        // Modal
        modalMessage={Wording.popup}
        updateResponse={updateResponse}
        handleValidate={this.handleUpdate}
        handleReset={resetCreateEventDispatch}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(FormPage);
