import React from 'react';

import CustomButton from '../CustomButton';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Common;

type Props = {
  show?: boolean;
  showCloseButton?: boolean;
  title: string;
  description: string;
  state?: number;
  customDescription?: string;
  handleCancel?: () => void;
  handleValidate?: () => void;
};

// state = 0 : success
// state = 1 : failure
// state = 2 : request
const Modal: React.FC<Props> = ({
  show,
  showCloseButton = true,
  title,
  description,
  state = 2,
  handleCancel,
  handleValidate,
  children,
  customDescription
}) => {
  const showBottom = () => {
    switch (state) {
      case 2:
        return (
          <div className="modal__content__buttons">
            <CustomButton
              title={Wording.popup.cancelButton}
              onClick={handleCancel}
              color={'gray'}
              arrowLeft
            />
            <CustomButton
              title={Wording.popup.validateButton}
              onClick={handleValidate}
              color={'orange'}
            />
          </div>
        );

      case 0:
      case 1:
        return (
          <div className="modal__content__image">
            <img src={resources['ic-logo-login']} alt="logo-login" />
          </div>
        );

      default:
        return false;
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={`modal${show ? ' modal--show-modal' : ''}`}
      onClick={handleCancel}
      onKeyDown={handleCancel}
    >
      <div
        role="button"
        tabIndex={0}
        className="modal__content"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {showCloseButton && (
          <span
            className="modal__content__close-button"
            onClick={handleCancel}
            role="button"
            tabIndex={0}
          >
            {Wording.popup.closeButton}
          </span>
        )}
        <div className="modal__content__title">
          <div className="layer">?</div>
          <h3>{title}</h3>
        </div>

        <div className="modal__content__description">
          <p>{description}</p>
          <p className="modal__content__description__custom-description">
            {customDescription}
          </p>
        </div>

        {showBottom()}

        {children}
      </div>
    </div>
  );
};

export default Modal;
