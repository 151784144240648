import React from 'react';

type Props = {
  text?: string;
  id?: string;
  checked?: boolean;
  handleCheck?: any;
  imageSrc?: string;
  description?: string;
};

const CustomCheckbox: React.FC<Props> = props => (
  <div
    className={`custom-checkbox ${props.description &&
      props.description !== '' &&
      'custom-check--withDescription'}`}
  >
    <input
      type="checkbox"
      id={props.id}
      checked={props.checked}
      onChange={props.handleCheck}
      value={props.text}
    />

    <label
      htmlFor={props.id}
      className={props.imageSrc ? 'custom-checkbox__label' : ''}
      onClick={props.handleCheck}
    >
      {props.imageSrc && <img src={props.imageSrc} className="" alt="" />}
      <div className="custom-checkbox__label__content">
        <p className="custom-checkbox__label__content__text">{props.text}</p>
        {props.description && props.description !== '' && (
          <p className="custom-checkbox__label__content__description">
            {props.description}
          </p>
        )}
      </div>
    </label>
  </div>
);

export default CustomCheckbox;
