import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState, Services } from 'Types';
import rootEpic from './root-epic';
import rootReducer from './root-reducer';
import services from '../services';

const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  Services
>({
  dependencies: services
});

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const currentState = localStorage.getItem('reduxState');
const persistedState = currentState ? JSON.parse(currentState) : {};
// const initialState = {};

const middlewares = [epicMiddleware];

const reducer = (state: any, action: RootAction) => {
  if (action.type === 'REDUX_RESET_STATE') {
    delete state.authReducer;
    delete state.profileReducer;
    delete state.contractReducer;
    delete state.packageReducer;
    delete state.fileReducer;
    delete state.eventReducer;
    delete state.consumptionReducer;
  }
  return rootReducer(state, action);
};

const store = createStore(
  reducer,
  persistedState,
  // initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

epicMiddleware.run(rootEpic);

export default store;
