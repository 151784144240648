import React from 'react';
import { InputEvent } from 'Types';

import { CustomInput } from '../../components';
import { IFields } from './types';

import { displayIban, isValidIban } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

const ModificationPayment: React.FC<{
  fields: IFields;
  showError: boolean;
  handleInputChange(e: InputEvent, section: string, type: string): void;
}> = ({ fields, showError, handleInputChange }) => {
  return (
    <>
      <h3 className="profile-container__payment-method__title">
        {Wording.paymentMethod.title}
      </h3>
      <div className="profile-container__update-payment-method">
        <CustomInput
          placeholder={Wording.paymentMethod.inputs.IBAN}
          value={displayIban(fields.billing.iban)}
          onInputChange={(e: InputEvent) =>
            handleInputChange(e, 'billing', 'iban')
          }
          error={
            !isValidIban(fields.billing.iban) && showError
              ? Wording.error.billing.iban
              : undefined
          }
        />
      </div>
    </>
  );
};

export default ModificationPayment;
