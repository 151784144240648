import React from 'react';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PaymentPage.balance;

type Props = {
  balance?: {
    customerBalance?: string;
    contractBalance: string;
    invoiceBalance?: string;
  };
};

const Balance: React.FC<Props> = props => {
  return (
    <div className="balance-container">
      <div className="balance-container__block">
        {props.balance ? (
          <React.Fragment>
            <div className="balance-container__block__head">
              <p>{Wording.title}</p>
              <h3>{`${props.balance && props.balance.contractBalance} €`}</h3>
            </div>
            <p className="balance-container__block__message">
              {Wording.message}
            </p>
          </React.Fragment>
        ) : (
          <p className="balance-container__block__error">{Wording.error}</p>
        )}
      </div>
    </div>
  );
};

export default Balance;
