import React from 'react';

import { InputEvent } from 'Types';
import CustomButton from '../CustomButton';

export type InputItem = {
  label: string;
  value: string;
  placeholder?: string;
  maxLength: number;
  error?: string;
  onChange(e: InputEvent): void;
};

export type Props = {
  title: string;
  description: string;
  inputs: InputItem[];
  buttonTitle: string;
  error?: string;
  handleInputChange(e: InputEvent, index: number): void;
  handleButtonClick(): void;
};

const CustomTransfer: React.FC<Props> = ({
  title,
  description,
  inputs,
  buttonTitle,
  handleInputChange,
  handleButtonClick,
  error
}) => {
  const displayInput = (input: InputItem, index: number) => {
    return (
      <div className="custom-transfer__inputs" key={index}>
        <div className="custom-transfer__inputs__content">
          <p className={`${input.label === '' && 'undefined'}`}>
            {input.label}
          </p>
          <input
            onChange={(e: InputEvent) => handleInputChange(e, index)}
            type="text"
            value={input.value}
            minLength={input.maxLength}
            maxLength={input.maxLength}
            placeholder={input.placeholder}
          />
        </div>
        {input.error && (
          <p className="custom-transfer__inputs__error">{input.error}</p>
        )}
      </div>
    );
  };

  return (
    <div className="custom-transfer">
      <h3>{title}</h3>
      {error ? (
        <p className="custom-transfer__error">{error}</p>
      ) : (
        <>
          <p>{description}</p>
          {inputs.map((input: InputItem, index: number) => {
            return displayInput(input, index);
          })}
          <CustomButton
            title={buttonTitle}
            color="orange"
            onClick={handleButtonClick}
          />
        </>
      )}
    </div>
  );
};

export default CustomTransfer;
