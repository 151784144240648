import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';
import { RootState, InputEvent } from 'Types';
import { TContract } from 'Models';
import { ECivility, EModeUpdateBilling } from '../../utils/enums';
import withBanner, {
  ExternalProps as ExternalPropsBanner
} from '../../libraries/withBanner';
import withModal, {
  ExternalProps as ExternalPropsModal,
  InjectedProps as InjectedPropsModal
} from '../../libraries/withModal';
import SearchService from '../../utils/search';

import {
  findContractByNumber,
  findCoholder,
  getTodayDate,
  getPhoneWithPrefix
} from '../../utils/helpers';
import { initialAddress } from '../../utils/initialState';

import {
  fetchContractAsync,
  resetContractState
} from '../../_actions/contract.actions';

import {
  updateCustomerAsync,
  resetUpdate,
  updateBillingAsync,
  IRequestUpdateCustomerCoholderEvent,
  IRequestUpdateBillingEvent
} from '../../_actions/profile.actions';
import ProfileContainer, {
  Props as ContainerProps
} from '../../containers/ProfileContainer/ProfileContainer';
import {
  IFields,
  ISetting,
  IBilling,
  IContact,
  IPostalCode
} from '../../containers/ProfileContainer/types';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

const mapStateToProps = (state: RootState) => ({
  profileState: state.profileReducer,
  contractState: state.contractReducer
});

const dispatchProps = {
  contractDispatch: fetchContractAsync.request,
  updateCustomerDispatch: updateCustomerAsync.request,
  resetUpdateDispatch: resetUpdate,
  updateBillingDispatch: updateBillingAsync.request,
  resetContractDispatch: resetContractState
};

type State = {
  fields: IFields;
  setting: ISetting;
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WrappedProfile = compose<
  ContainerProps,
  Subtract<ContainerProps, InjectedPropsModal> &
    ExternalPropsBanner &
    ExternalPropsModal
>(
  withBanner(),
  withModal()
)(ProfileContainer);

class ProfilePage extends React.Component<Props, State> {
  searchService: any;

  constructor(props: Props) {
    super(props);

    const { profileState, contractState } = this.props;
    const contract = findContractByNumber(
      contractState.selectedContractNumber,
      contractState.contracts
    );
    const contact: IContact = {
      civility: profileState.contact.customerFullName.civility,
      firstName: profileState.contact.customerFullName.firstName,
      lastName: profileState.contact.customerFullName.lastName,
      birthdate: profileState.contact.birthdate,
      address: profileState.contact.address,
      email: profileState.contact.email,
      phone: profileState.contact.phone
    };
    const coholder = findCoholder(contract);
    if (coholder) {
      contact.coholderCivility = coholder.civility as ECivility;
      contact.coholderFirstName = coholder.firstName;
    } else {
      contact.coholderCivility = ECivility.MR;
    }
    const billing = {
      civility: ECivility.MR,
      firstName: '',
      lastName: '',
      email: '',
      iban: '',
      address: {
        ...initialAddress
      }
    };
    billing.iban = contract.finance.ibanCode || '';
    if (contract.finance.billingContact) {
      billing.email = contract.finance.billingContact.email;
    }
    const checkCoholder =
      coholder !== undefined &&
      coholder.civility !== undefined &&
      coholder.firstName !== undefined;
    this.state = {
      fields: {
        contractNumber: '',
        customerNumber: '',
        contact,
        billing
      },
      setting: {
        loadingPostalCode: false,
        addressVal: '',
        postalCodes: [],
        pc: '',
        showErrorBilling: false,
        showErrorCustomer: false,
        showErrorIban: false,
        checkCoholder
      }
    };

    this.searchService = new SearchService();
  }

  componentDidMount() {
    this.searchService.getResults().subscribe((res: any) => {
      const { setting } = this.state;
      setting.postalCodes = res.map((dt: any) => ({
        label: `${dt.code} - - - ${dt.city}`,
        value: `${dt.code}__${dt.city}`,
        codeValue: dt.code,
        cityValue: dt.city,
        netAreaValue: dt.netArea
      }));
      setting.loadingPostalCode = false;
      this.setState({ setting });
    });
  }

  componentWillUnmount() {
    this.searchService.unsubscribe();
  }

  handleAddressChange = (value: string, type: string) => {
    const { setting, fields } = this.state;
    if (type === 'postalCode') {
      setting.pc = value.replace(/[^\d ]/g, '');
      setting.loadingPostalCode = true;
      this.searchService.search(value);
      this.setState({ setting });
    } else {
      setting.addressVal = value;
      const splitArr = value.split(' ');
      if (splitArr[0] === '') {
        splitArr.shift();
      }
      fields.billing.address.number = splitArr[0];
      splitArr.shift();
      fields.billing.address.street = splitArr.join(' ').trim();
      this.setState({ fields, setting });
    }
  };

  handleChangeDropdown = ({
    value,
    codeValue,
    cityValue,
    netAreaValue
  }: IPostalCode) => {
    const { fields, setting } = this.state;
    fields.billing.address.netArea = netAreaValue;
    fields.billing.address.postalCode = codeValue;
    fields.billing.address.townName = cityValue;
    fields.billing.currentPostalCode = {
      value,
      label: `${codeValue}`,
      codeValue,
      cityValue,
      netAreaValue
    };
    setting.postalCodes = [];
    this.setState({ fields, setting });
  };

  onFocusDropdown = () => {
    const { setting, fields } = this.state;
    if (fields.billing.address.postalCode !== '') {
      setting.pc = fields.billing.address.postalCode;
    }
    this.setState({ setting });
  };

  handleInputChange = (e: InputEvent, section: string, type: string) => {
    const { fields } = this.state;
    const s = section === 'iban' ? 'billing' : section;
    fields[s][type] = e.currentTarget.value;
    this.setState({ fields });
  };

  handleChangeCivility = (section: string, value: string) => {
    const { fields } = this.state;
    if (section === 'contact') {
      fields[section].coholderCivility = value as ECivility;
    } else {
      fields[section].civility = value as ECivility;
    }
    this.setState({ fields });
  };

  handleResetCoholder = (civility?: string, firstName?: string) => {
    const { fields, setting } = this.state;
    if (!setting.checkCoholder) {
      fields.contact.coholderCivility = civility as ECivility;
      fields.contact.coholderFirstName = firstName;
    } else {
      fields.contact.coholderCivility = ECivility.MR;
      fields.contact.coholderFirstName = undefined;
    }
    setting.checkCoholder = !setting.checkCoholder;
    this.setState({ fields, setting });
  };

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber }
    } = this.props;
    contractDispatch({
      customerNbr: customerNumber,
      contractNbr: contractNumber
    });
  };

  handleUpdate = (section: string) => {
    const { fields, setting } = this.state;
    const {
      updateCustomerDispatch,
      profileState,
      updateBillingDispatch,
      contractState
    }: Props = this.props;

    switch (section) {
      case 'contact': {
        const payload: IRequestUpdateCustomerCoholderEvent = {
          customer: {
            customerNbr: profileState.customerNumber,
            email: fields.contact.email,
            phone: getPhoneWithPrefix(fields.contact.phone),
            marketing: profileState.contact.marketing,
            newsletter: profileState.contact.newsletter
          },
          coholder: undefined,
          event: {
            customerNbr: profileState.customerNumber,
            contractNbr: contractState.selectedContractNumber,
            category: Wording.categoryToSend,
            date: getTodayDate(),
            subCategory: Wording.subCategories.consumptionContactChange.value,
            message: Wording.subCategories.consumptionContactChange.label
          }
        };
        if (
          fields.contact.coholderCivility &&
          fields.contact.coholderFirstName &&
          fields.contact.coholderFirstName !== '' &&
          profileState.customerType !== 'PROFESSIONAL'
        ) {
          payload.coholder = {
            mode: 'insertOrUpdate',
            contractNbr: contractState.selectedContractNumber,
            civility: fields.contact.coholderCivility,
            firstName: fields.contact.coholderFirstName
          };
          payload.event.message =
            Wording.subCategories.consumptionContactChange.addCoholder;
        } else {
          const contract = findContractByNumber(
            contractState.selectedContractNumber,
            contractState.contracts
          );
          const coholder = findCoholder(contract);
          if (coholder && coholder.firstName) {
            payload.coholder = {
              mode: 'delete',
              contractNbr: contractState.selectedContractNumber,
              civility: coholder.civility as ECivility,
              firstName: coholder.firstName || ''
            };
            payload.event.message =
              Wording.subCategories.consumptionContactChange.deleteCoholder;
            setting.checkCoholder = false;
            this.setState({ setting });
          }
        }
        updateCustomerDispatch(payload);
        break;
      }

      case 'billing': {
        const payload: IRequestUpdateBillingEvent = {
          paymentMode: {
            mode: EModeUpdateBilling.BILLING_ADDRESS,
            contractNbr: contractState.selectedContractNumber,
            billingAddress: fields.billing.address,
            customerFullName: {
              civility: fields.billing.civility,
              firstName: fields.billing.firstName,
              lastName: fields.billing.lastName
            },
            email: fields.billing.email
          },
          event: {
            customerNbr: profileState.customerNumber,
            contractNbr: contractState.selectedContractNumber,
            category: Wording.categoryToSend,
            date: getTodayDate(),
            subCategory: Wording.subCategories.invoicingContactChange.value,
            message: Wording.subCategories.invoicingContactChange.label
          }
        };
        updateBillingDispatch(payload);
        break;
      }

      case 'iban': {
        const { contracts, selectedContractNumber } = contractState;
        const contract = findContractByNumber(
          selectedContractNumber,
          contracts
        );

        const payload: IRequestUpdateBillingEvent = {
          paymentMode: {
            mode: EModeUpdateBilling.IBAN,
            contractNbr: contractState.selectedContractNumber,
            ibanCode: fields.billing.iban,
            rumCode: contract.finance.rumCode
          },
          event: {
            customerNbr: profileState.customerNumber,
            contractNbr: contractState.selectedContractNumber,
            category: Wording.categoryToSend,
            date: getTodayDate(),
            subCategory: Wording.subCategories.paymentModeChange.value,
            message: Wording.subCategories.paymentModeChange.label
          }
        };
        updateBillingDispatch(payload);
        break;
      }

      default:
        break;
    }
  };

  handleShowErrorMessage = (section: string): void => {
    const { setting } = this.state;
    if (section === 'billing') {
      setting.showErrorBilling = true;
    } else if (section === 'contact') {
      setting.showErrorCustomer = true;
    } else {
      setting.showErrorIban = true;
    }
    this.setState({ setting });
  };

  /**
   * By default, use the contact information to be displayed on billing section
   * These params cannot be modified using state
   */
  getBilling = (contract: TContract): IBilling => {
    const {
      profileState: { contact }
    } = this.props;
    const finance = contract.finance;
    const billing = {
      civility: contact.customerFullName.civility,
      firstName: contact.customerFullName.firstName,
      lastName: contact.customerFullName.lastName,
      email: contact.email,
      phone: contact.phone,
      iban: '',
      address: contact.address
    };
    if (finance) {
      billing.iban = finance.ibanCode;
      if (finance.billingContact) {
        billing.email = finance.billingContact.email;
        billing.phone = finance.billingContact.phone;
        if (finance.billingContact.customerFullName) {
          billing.civility = finance.billingContact.customerFullName.civility;
          billing.firstName = finance.billingContact.customerFullName.firstName;
          billing.lastName = finance.billingContact.customerFullName.lastName;
        }
        if (finance.billingContact.address) {
          billing.address = finance.billingContact.address;
        }
      }
    }
    return billing;
  };

  /**
   * Load all contact info necessary to be displayed on contact section
   * These params cannot be modified using state
   */
  getContact = (contract: TContract): IContact => {
    const {
      profileState: { contact }
    } = this.props;
    const thirdParties = contract.thirdParties;
    const currentContact: IContact = {
      civility: contact.customerFullName.civility,
      firstName: contact.customerFullName.firstName,
      lastName: contact.customerFullName.lastName,
      email: contact.email,
      phone: contact.phone,
      birthdate: contact.birthdate,
      address: contact.address,
      companyName: contact.companyName,
      companyType: contact.companyType,
      activityCode: contact.activityCode,
      identificationNumber: contact.identificationNumber
    };
    if (thirdParties) {
      const coholder = thirdParties.find(p => p.role === 'COHOLDER') || {
        civility: ECivility.MR,
        firstName: undefined
      };
      currentContact.coholderCivility = coholder.civility as ECivility;
      currentContact.coholderFirstName = coholder.firstName;
    }
    return currentContact;
  };

  render() {
    const {
      profileState: { contact, updateResponse, customerType },
      contractState: { contracts, selectedContractNumber, error },
      resetUpdateDispatch,
      resetContractDispatch
    }: Props = this.props;

    const { setting, fields } = this.state;
    const contract = findContractByNumber(selectedContractNumber, contracts);
    const billing = this.getBilling(contract);
    const currentContact = this.getContact(contract);

    return (
      <WrappedProfile
        setting={setting}
        fields={fields}
        currentContact={currentContact}
        billing={billing}
        handleInputChange={this.handleInputChange}
        handleChangeCivility={this.handleChangeCivility}
        handleResetCoholder={this.handleResetCoholder}
        handleChangeDropdown={this.handleChangeDropdown}
        handleAddressChange={this.handleAddressChange}
        onFocusDropdown={this.onFocusDropdown}
        handleShowErrorMessage={this.handleShowErrorMessage}
        // Banner
        title={Wording.title}
        bannerError={error}
        handleCloseModalBanner={() => resetContractDispatch()}
        selectedContractNumber={selectedContractNumber}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        // Modal
        modalMessage={Wording.popup}
        updateResponse={updateResponse}
        handleValidate={this.handleUpdate}
        handleReset={resetUpdateDispatch}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(ProfilePage);
