import React, { useState } from 'react';

import { InputEvent } from 'Types';
import { CustomInput, CustomButton, CustomModal } from '../../components/index';
import { isValidEmail } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.ParentingPage;

export type Props = {
  fields: {
    email: string;
    error?: string;
  };
  handleInputChange(e: InputEvent, type: string): void;
  handleInputError(): boolean;
  handleShowModal(section: string): void;
};

const SponsorContainer: React.FC<Props> = ({
  fields: { email, error },
  handleInputChange,
  handleInputError,
  handleShowModal
}) => {
  const [showModal, setModal] = useState<boolean>(false);

  const isEmail = isValidEmail(email);

  const renderModal = () => {
    return (
      <CustomModal
        show={showModal}
        showCloseButton
        handleClick={() => setModal(false)}
      >
        <div className="custom-modal__inside-content">
          <h1>{Wording.rulePopup.title}</h1>
          {Wording.rulePopup.descriptions.map((e, index) => {
            if (e.id === 'title') {
              return <h3 key={index}>{e.text}</h3>;
            }
            return <p key={index}>{e.text}</p>;
          })}
        </div>
      </CustomModal>
    );
  };

  return (
    <div className="sponsor-container">
      {renderModal()}
      <h2 className="sponsor-container__subtitle">{Wording.subTitle}</h2>
      <p className="sponsor-container__subtitle2">{Wording.textParenting}</p>
      <p className="sponsor-container__inputTitle">{Wording.inputTitle}</p>
      <CustomInput
        placeholder={Wording.placholderInput}
        value={email}
        error={error}
        onInputChange={(e: InputEvent) => handleInputChange(e, 'email')}
      />
      <div
        role="button"
        onClick={() => {
          setModal(true);
        }}
        className="sponsor-container__rule"
      >
        {Wording.ruleDescription}
      </div>
      <CustomButton
        title={Wording.buttonValue}
        color={isEmail ? 'orange' : 'gray'}
        onClick={() => {
          if (handleInputError()) {
            handleShowModal('');
          }
        }}
      />
    </div>
  );
};

export default SponsorContainer;
