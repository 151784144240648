import React from 'react';

import { InputEvent } from 'Types';
import { IFields } from './types';
import { CustomInput } from '../../components';
import {
  isValidEmail,
  isValidPhone,
  displayPhoneWithoutPrefix
} from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

const ModificationContact: React.FC<{
  fields: IFields;
  showError: boolean;
  handleInputChange(e: InputEvent, section: string, type: string): void;
}> = props => {
  const {
    fields: {
      contact: { email, phone }
    },
    handleInputChange,
    showError
  } = props;

  const phoneUpdated = displayPhoneWithoutPrefix(phone);
  return (
    <div className="profile-container__inputs">
      <div className="profile-container__inputs__content">
        <h3>{Wording.consumption.title}</h3>
        <CustomInput
          placeholder={Wording.consumption.inputs.email}
          onInputChange={(e: InputEvent) =>
            handleInputChange(e, 'contact', 'email')
          }
          value={email}
          error={
            !isValidEmail(email) && showError ? Wording.error.email : undefined
          }
        />
        <CustomInput
          placeholder={Wording.consumption.inputs.phone}
          onInputChange={(e: InputEvent) =>
            handleInputChange(e, 'contact', 'phone')
          }
          minLength={10}
          maxLength={10}
          value={phoneUpdated}
          error={
            !isValidPhone(phoneUpdated) && showError
              ? Wording.error.phone
              : undefined
          }
        />
      </div>
      <div className="profile-container__inputs__right" />
    </div>
  );
};

export default ModificationContact;
