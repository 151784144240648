import React from 'react';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import CustomButton from '../CustomButton';

export const LoadingComponent: React.FC<{ loading: boolean }> = ({
  loading
}) => {
  if (loading) {
    return (
      <div className="custom-loading -loading -active">
        <div className="custom-loading-inner -loading-inner">
          Chargement en cours...
        </div>
      </div>
    );
  }
  return null;
};

type Props = {
  columns: object[];
  data: any;
  title?: string;
  loading?: boolean;
  defaultPageSize?: number;
  noDataText?: string;
  sortable?: boolean;
  buttonTitle?: string;
  error?: string;
  SubComponen?: any;
  showPagination?: boolean;
  className?: string;
  handleButtonClick?(): void;
};

const CustomTable: React.FC<Props> = ({
  columns,
  data,
  title,
  loading,
  defaultPageSize,
  noDataText,
  sortable,
  buttonTitle,
  handleButtonClick,
  error,
  SubComponen,
  showPagination,
  className
}) => (
  <div className="custom-table">
    {title && <h3>{title}</h3>}

    <ReactTable
      className={`custom-table__table ${className && className}`}
      columns={columns}
      data={data}
      pageSize={data.length === 0 ? 2 : defaultPageSize}
      showPaginationBottom={false}
      loading={loading}
      LoadingComponent={LoadingComponent}
      noDataText={noDataText}
      sortable={sortable}
      SubComponent={SubComponen}
      showPagination={showPagination}
    />
    {!error && buttonTitle && (
      <CustomButton
        title={buttonTitle}
        color="orange"
        onClick={handleButtonClick}
      />
    )}
  </div>
);

export default CustomTable;
