import React from 'react';
import Select from 'react-select';

import { InputEvent } from 'Types';
import { IFields, ISetting } from './types';
import { CustomInput, CustomSelect } from '../../components';
import { isValidEmail, getLabelCivility } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

type InfoInput = {
  placeholder: string;
  value: string;
  label: string;
  error?: string;
};

const ModificationBilling: React.FC<{
  handleChangeCivility: any;
  fields: IFields;
  setting: ISetting;
  handleChangeDropdown: any;
  handleAddressChange: any;
  onFocusDropdown: any;
  handleInputChange(e: InputEvent, section: string, type: string): void;
}> = ({
  fields,
  setting,
  handleInputChange,
  handleAddressChange,
  handleChangeDropdown,
  handleChangeCivility,
  onFocusDropdown
}) => {
  const { billing } = fields;
  const { showErrorBilling } = setting;

  const infoInputs: InfoInput[] = [
    {
      placeholder: Wording.billing.contact.lastName,
      value: billing.lastName,
      label: 'lastName',
      error:
        billing.lastName === '' && showErrorBilling
          ? Wording.error.billing.lastName
          : undefined
    },
    {
      placeholder: Wording.billing.contact.firstName,
      value: billing.firstName,
      label: 'firstName',
      error:
        billing.firstName === '' && showErrorBilling
          ? Wording.error.billing.firstName
          : undefined
    },
    {
      placeholder: Wording.billing.contact.email,
      value: billing.email,
      label: 'email',
      error:
        showErrorBilling && !isValidEmail(billing.email)
          ? Wording.error.billing.email
          : undefined
    }
  ];
  return (
    <div className="profile-container__update-billing">
      <div className="profile-container__update-billing__contact">
        <h3>{Wording.billing.title}</h3>
        <CustomSelect
          id="civility"
          defaultValue={billing.civility}
          options={Wording.civility.values}
          handleChangeSelect={(e: any) =>
            handleChangeCivility('billing', e.value)
          }
          value={billing.civility}
          error={
            getLabelCivility(billing.civility) === '' && showErrorBilling
              ? Wording.error.mainText
              : undefined
          }
        />
        {infoInputs.map((info: InfoInput, index: number) => (
          <CustomInput
            key={index}
            placeholder={info.placeholder}
            value={info.value}
            onInputChange={(e: InputEvent) =>
              handleInputChange(e, 'billing', info.label)
            }
            maxLength={info.label === 'phone' ? 10 : 200}
            error={info.error}
          />
        ))}
      </div>
      <div className="profile-container__update-billing__address">
        <h3>{Wording.billing.contact.address}</h3>
        <CustomInput
          value={setting.addressVal || ''}
          placeholder={Wording.billing.contact.address}
          onInputChange={(e: InputEvent) =>
            handleAddressChange(e.currentTarget.value, 'address')
          }
          error={
            setting.addressVal === '' && showErrorBilling
              ? Wording.error.billing.address
              : undefined
          }
        />

        <Select
          className="select"
          classNamePrefix="react-select"
          placeholder={Wording.billing.contact.postalCode}
          onInputChange={(val: string) => {
            handleAddressChange(val, 'postalCode');
          }}
          onChange={handleChangeDropdown}
          inputValue={setting.pc}
          onFocus={onFocusDropdown}
          options={setting.postalCodes}
          value={billing.currentPostalCode}
          defaultValue={billing.currentPostalCode}
          styles={{
            placeholder: () => ({
              color: billing.address.postalCode !== '' ? 'black' : '#746d6d'
            })
          }}
        />
        {billing.address.postalCode === '' && showErrorBilling && (
          <p className="profile-container__update-billing__address__errorPostalCode">
            {Wording.error.billing.postalCode}
          </p>
        )}

        <CustomInput
          value={billing.address.townName || ''}
          isLocked={true}
          placeholder={Wording.billing.contact.city}
          onInputChange={() => {}}
        />
      </div>
    </div>
  );
};

export default ModificationBilling;
