import React from 'react';

import { TPackage } from 'Models';

import { PackageElement, EnergyGauge } from '../../components';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PackagePage;
const CommonWording = WordingConstant.Common;

export type Props = {
  loading: boolean;
  packages?: TPackage[];
  ranges: number[][];
  showGaugeTab: boolean;
  prices: number[];
  packageID: string;
  handleToggleGauge(gauge: boolean): void;
  handleChangePackageID(packageID: string): void;
  handleChangePrices(prices: number[]): void;
};

const GaugeContainer: React.FC<Props> = ({
  loading,
  packages,
  ranges,
  prices,
  packageID,
  showGaugeTab,
  handleToggleGauge,
  handleChangePackageID,
  handleChangePrices
}) => {
  let initialProgress = 0;
  if (prices && prices.length > 1) {
    initialProgress = ranges.findIndex(
      r => r[0] === prices[0] && r[1] === prices[1]
    );
    initialProgress = initialProgress === -1 ? 0 : initialProgress;
  }
  const reducer = (accumulator: any, current: any) => {
    const max = current[0] > current[1] ? current[0] : current[1];
    return accumulator > max ? accumulator : max;
  };
  const gaugeMaxValue = ranges.reduce(reducer, 0);

  return (
    <div className="gauge-container">
      <p className="gauge-container__title">{Wording.plan.gauge.title}</p>

      <div className="gauge-container__gauge">
        <input
          id="tab1"
          type="radio"
          checked={!showGaugeTab}
          onChange={() => handleToggleGauge(false)}
        />
        <input
          id="tab2"
          type="radio"
          checked={showGaugeTab}
          onChange={() => handleToggleGauge(true)}
        />

        <ul>
          <li className="tab1">
            <label htmlFor="tab1">{Wording.plan.gauge.tab1}</label>
          </li>
          <li className="tab2">
            <label htmlFor="tab2">{Wording.plan.gauge.tab2}</label>
          </li>
        </ul>

        <section>
          {!showGaugeTab ? (
            <React.Fragment>
              <div className="tab1">
                {packages ? (
                  packages.map(({ amount, id, recommended }, index) => (
                    <PackageElement
                      key={index}
                      price={amount}
                      chosen={id === packageID}
                      recommended={recommended}
                      onClick={() => {
                        handleChangePackageID(id);
                      }}
                    />
                  ))
                ) : (
                  <p>
                    {loading ? CommonWording.isLoading : CommonWording.noData}
                  </p>
                )}
              </div>

              <div
                className="modal"
                onClick={() =>
                  // handleToggle('modalPackage', !modalPackage)
                  {}
                }
                tabIndex={0}
                role="button"
              >
                {Wording.plan.popupChoosePackage}
              </div>
            </React.Fragment>
          ) : (
            <div className="tab2">
              {ranges.length > 0 && ranges[0].length > 0 ? (
                <EnergyGauge
                  initial={initialProgress}
                  maxValue={gaugeMaxValue}
                  ranges={ranges}
                  onChange={(p: number[]) => {
                    handleChangePrices(p);
                  }}
                />
              ) : (
                <p>{CommonWording.noData}</p>
              )}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default GaugeContainer;
