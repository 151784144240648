import React from 'react';
import { Link } from 'react-router-dom';
import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

import routes, { RouteProps, SubRouteProps } from '../../pages/routes';
import LogoutButton from './LogoutButton';

const Wording = WordingConstant.MainPage.SideBar;

type Props = {
  firstName: string;
  lastName: string;
  customerNbr: string;
  handleLogout(): void;
};

const SideBar: React.FC<Props> = ({
  firstName,
  lastName,
  customerNbr,
  handleLogout
}) => {
  return (
    <div className="side-bar">
      <img src={resources['ic-logo-sidebar']} alt="ic-logo" />
      <div className="side-bar__data-private">
        <img src={resources['ic-avatar']} alt="ic-avatar" />

        <div className="side-bar__data-private__name-number">
          <p>
            {firstName} {lastName}
          </p>
          <p>
            {Wording.numClient}
            {customerNbr}
          </p>
          <LogoutButton handleLogout={handleLogout} />
        </div>
      </div>

      <div className="side-bar__navigation">
        <ul className="side-bar__navigation__list">
          {routes.map((route: RouteProps, index: number) => (
            <React.Fragment key={index}>
              <h2 className="side-bar__navigation__list__title" key={index}>
                {route.title}
              </h2>
              <ul>
                {route.links.map(
                  (subRoute: SubRouteProps, indexRoute: number) => (
                    <li key={indexRoute}>
                      {(subRoute.title ===
                        Wording.routes.contact.links.FAQ.title && (
                        <a
                          href={subRoute.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {route.title}
                        </a>
                      )) || (
                        <Link to={route.path + subRoute.path}>
                          {subRoute.title}
                        </Link>
                      )}
                    </li>
                  )
                )}
              </ul>
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
