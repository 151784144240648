import React from 'react';

import { TPiece } from 'Models';

import ArticleVideoContainer from '../../containers/ArticleVideoContainer';
import PaymentStatusContainer from '../../containers/PaymentStatusContainer';
import {
  renderPiecesFaild,
  prepareFaildPieces
} from '../PaymentContainer/PaymentContainer';
import articles from '../../assets/articles.json';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.HomePage;

export type Props = {
  pieces: TPiece[];
  fields: {
    pieceId: string;
    pieceDate: string;
    pieceType: string;
    executionDate: string;
  };
  handleChangeFildPieces: ({
    pieceId,
    executionDate,
    pieceDate,
    pieceType
  }: {
    pieceId: string;
    pieceDate: string;
    pieceType: string;
    executionDate: string;
  }) => void;
  handleShowModal(section: string): void;
};

const HomeContainer: React.FC<Props> = ({
  pieces,
  handleChangeFildPieces,
  handleShowModal
}) => {
  const faildPieces = prepareFaildPieces(pieces);
  return (
    <div className="home-container">
      <h2 className="home-container__subTitle">{Wording.subTitle}</h2>
      {pieces.length !== 0 && <PaymentStatusContainer pieces={pieces} />}

      {faildPieces && faildPieces.length !== 0 && (
        <h2>{Wording.incidentPaiementText}</h2>
      )}

      {renderPiecesFaild(faildPieces, handleChangeFildPieces, handleShowModal)}
      <h2 className="home-container__articlVideo">{Wording.articlVideoText}</h2>
      <ArticleVideoContainer
        articles={articles.articles}
        videos={articles.videos}
      />
    </div>
  );
};

export default HomeContainer;
