import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';
import { RootState } from 'Types';
import { EEnergy, ECustomerType } from '../../utils/enums';

import withBanner, {
  ExternalProps as ExternalPropsBanner
} from '../../libraries/withBanner';
import withModal, {
  ExternalProps as ExternalPropsModal,
  InjectedProps as InjectedPropsModal
} from '../../libraries/withModal';

import PackageContainer, {
  Props as ContainerProps
} from '../../containers/PackageContainer/PackageContainer';
import {
  fetchContractAsync,
  resetContractState
} from '../../_actions/contract.actions';
import {
  fetchPackageAsync,
  fetchProductAsync,
  updatePackageAsync,
  resetUpdate,
  IPayloadUpdatePackage as PackagePayload
} from '../../_actions/package.actions';

import { findContractByNumber } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PackagePage;

const mapStateToProps = (state: RootState) => ({
  authState: state.authReducer,
  profileState: state.profileReducer,
  contractState: state.contractReducer,
  packageState: state.packageReducer
});

const dispatchProps = {
  contractDispatch: fetchContractAsync.request,
  fetchPackageDispatch: fetchPackageAsync.request,
  fetchProductDispatch: fetchProductAsync.request,
  updatePackageDispatch: updatePackageAsync.request,
  resetContractDispatch: resetContractState,
  resetUpdateDispatch: resetUpdate
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WrappedPackage = compose<
  ContainerProps,
  Subtract<ContainerProps, InjectedPropsModal> &
    ExternalPropsBanner &
    ExternalPropsModal
>(
  withBanner(),
  withModal()
)(PackageContainer);

type State = {
  options: [];
  additionalOptions: [];
  payload?: PackagePayload;
};

class PackagePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      options: [],
      additionalOptions: [],
      payload: undefined
    };
  }

  componentDidMount() {
    const {
      fetchProductDispatch,
      profileState: { customerType },
      contractState: { contracts, selectedContractNumber }
    } = this.props;
    const contract = findContractByNumber(selectedContractNumber, contracts);

    fetchProductDispatch({
      customerType: customerType as ECustomerType,
      contracts: [contract]
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { contractState } = prevProps;
    const {
      fetchProductDispatch,
      profileState: { customerType },
      contractState: { contracts, selectedContractNumber }
    } = this.props;
    if (selectedContractNumber !== contractState.selectedContractNumber) {
      const contract = findContractByNumber(selectedContractNumber, contracts);
      fetchProductDispatch({
        customerType: customerType as ECustomerType,
        contracts: [contract]
      });
    }
  }

  handleFetchPackage = () => {
    const {
      fetchPackageDispatch,
      profileState: { customerType },
      contractState: { contracts, selectedContractNumber }
    } = this.props;
    const contract = findContractByNumber(selectedContractNumber, contracts);

    fetchPackageDispatch({
      customerType: customerType as ECustomerType,
      contractNbr: selectedContractNumber,
      energy: contract.energy as EEnergy,
      reference: contract.deliveryPoint.pointOfDelivery
    });
  };

  handleUpdate = () => {
    const { payload } = this.state;
    if (payload) {
      const {
        profileState: { customerNumber },
        contractState: { contracts, selectedContractNumber },
        updatePackageDispatch
      } = this.props;
      const contract = findContractByNumber(selectedContractNumber, contracts);
      updatePackageDispatch({
        customerNbr: customerNumber,
        contract,
        ...payload
      });
    }
  };

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber }
    } = this.props;
    contractDispatch({
      customerNbr: customerNumber,
      contractNbr: contractNumber
    });
  };

  render() {
    const {
      profileState: { contact, customerType },
      contractState: { contracts, selectedContractNumber, error },
      packageState: {
        loadingPackage,
        loadingOffer,
        pkg,
        products,
        offer,
        prepaymentFrequencies,
        updateResponse,
        description
      },
      resetUpdateDispatch,
      resetContractDispatch
    }: Props = this.props;

    const contract = findContractByNumber(selectedContractNumber, contracts);

    const packages = pkg[selectedContractNumber];
    const currentOffer = offer[selectedContractNumber];
    const pf = prepaymentFrequencies[selectedContractNumber];

    return (
      <WrappedPackage
        loadingPackage={loadingPackage}
        loadingOffer={loadingOffer}
        contract={contract}
        packages={packages}
        products={products}
        prepaymentFrequencies={pf}
        offer={currentOffer}
        handleFetchPackage={this.handleFetchPackage}
        handleSetPayload={(payload: PackagePayload) =>
          this.setState({
            payload: {
              ...payload,
              customerType
            }
          })
        }
        // Banner
        title={Wording.title}
        bannerError={error}
        handleCloseModalBanner={() => resetContractDispatch()}
        selectedContractNumber={selectedContractNumber}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        // Modal
        modalMessage={Wording.popup}
        customDescription={description}
        updateResponse={updateResponse}
        handleValidate={this.handleUpdate}
        handleReset={() => resetUpdateDispatch()}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(PackagePage);
