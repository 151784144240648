import { Subject, of, from, throwError } from 'rxjs';
import {
  debounceTime,
  switchMap,
  distinctUntilChanged,
  mergeMap,
  catchError
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import Config from './config';

interface IResProps {
  netArea: string;
  postalCode: string;
  townName: string;
}

class SearchService {
  private handleSearch$: any;
  constructor() {
    this.handleSearch$ = new Subject<string>().pipe(debounceTime(100));
  }

  public unsubscribe = () => {
    this.handleSearch$.unsubscribe();
  };

  public search = (term: string) => {
    this.handleSearch$.next(term);
  };

  public doSearch = (term: string) => {
    const settings = {
      url: `${Config.URL_SUBSCRIPTION}/getTowns`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': Config.ApiKey
      },
      responseType: 'json',
      method: 'post',
      crossDomain: true,
      body: {
        postalCode: term
      }
    };
    const promise = ajax(settings).pipe(
      catchError(({ message }) => throwError({ error: message })),
      mergeMap(response =>
        of(
          response.response
            ? response.response.map((res: IResProps) => ({
                code: res.postalCode,
                city: res.townName,
                netArea: res.netArea
              }))
            : []
        )
      )
    );
    return from(promise);
  };

  public getResults() {
    return this.handleSearch$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term: string) => (term ? this.doSearch(term) : of([]))),
      catchError(error => {
        console.error(error);
        return of([]);
      })
    );
  }
}

export default SearchService;
