import React from 'react';

import { CustomButton } from '../../components';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

const ModificationButtons: React.FC<{
  showButton: boolean;
  condition: boolean;
  handleButtonClick(section: string, option?: boolean): void;
  handleButtonCancel(section: string): void;
  handleButtonValidate(): void;
}> = ({
  showButton,
  condition,
  handleButtonClick,
  handleButtonCancel,
  handleButtonValidate
}) => (
  <>
    {showButton ? (
      <CustomButton
        color="orange"
        title={Wording.buttons.update}
        onClick={handleButtonClick}
      />
    ) : (
      <div className="profile-container__buttons">
        <CustomButton
          title={Wording.buttons.cancel}
          onClick={handleButtonCancel}
          color={'gray'}
        />
        <CustomButton
          title={Wording.buttons.validate}
          onClick={handleButtonValidate}
          color={condition ? 'orange' : 'gray'}
        />
      </div>
    )}
  </>
);

export default ModificationButtons;
