import React from 'react';

import { CustomTable } from '../../components/index';

import { TPiece } from 'Models';

import { getLabelByValue, getFormattedDate } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.BillPage;

type Props = {
  data: TPiece[];
  handleDownloadBill: (key: string) => Promise<void>;
};

const BillContainer: React.FC<Props> = props => {
  const columnsTable = [
    {
      Header: 'DATE',
      accessor: 'pieceDate',
      Cell: (propsCell: any) => getFormattedDate(propsCell.value),
      width: 150
    },
    {
      Header: 'TYPE',
      accessor: 'pieceType',
      Cell: (propsCell: any) =>
        getLabelByValue(propsCell.value, Wording.typePieces),
      width: 150
    },
    {
      Header: 'MONTANT',
      accessor: 'pieceAmount',
      Cell: (propsCell: any) => <span>{propsCell.value} €</span>,
      width: 450
    },
    {
      Header: 'TÉLÉCHARGER',
      accessor: 'pieceId',
      Cell: (propsCell: any) => {
        return (
          <a href="#" onClick={() => props.handleDownloadBill(propsCell.value)}>
            Fichier
          </a>
        );
      },
      width: 200
    }
  ];
  return (
    <div className="bill-container">
      <h2 className="bill-container__amount">{Wording.amount}</h2>
      <CustomTable
        columns={columnsTable}
        data={props.data}
        defaultPageSize={props.data && props.data.length}
        sortable={false}
        noDataText={Wording.noDataText}
      />
    </div>
  );
};
export default BillContainer;
