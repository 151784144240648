import React from 'react';
import { connect } from 'react-redux';

import MoveContainer from '../../containers/MoveContainer';

interface IProps {
  mainState: any;
}

class MovePage extends React.Component<IProps> {
  handleClick = (url: string) => {
    window.open(url);
  };

  render() {
    return <MoveContainer handleClick={this.handleClick} />;
  }
}

const mapStateToProps = (state: any) => ({
  mainState: state.mainPageReducer
});

export default connect(mapStateToProps)(MovePage);
