import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';

import EventContainer from '../../containers/EventContainer/';
import { fetchEventAsync } from '../../_actions/event.actions';
import { fetchContractAsync } from '../../_actions/contract.actions';
import withBanner from '../../libraries/withBanner';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.RequestPage;

const mapStateToProps = (state: RootState) => ({
  eventState: state.eventReducer,
  profileState: state.profileReducer,
  contractState: state.contractReducer
});

const dispatchProps = {
  eventDispatch: fetchEventAsync.request,
  contractDispatch: fetchContractAsync.request
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const WrappedEvent = withBanner()(EventContainer);

export class EventPage extends Component<Props, {}> {
  componentDidMount() {
    const { profileState, contractState, eventDispatch }: Props = this.props;

    eventDispatch({
      contractNbr: contractState.selectedContractNumber,
      customerNbr: profileState.customerNumber
    });
  }

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber },
      eventDispatch
    } = this.props;
    const payload = {
      customerNbr: customerNumber,
      contractNbr: contractNumber
    };
    contractDispatch(payload);
    eventDispatch(payload);
  };

  render() {
    const {
      eventState: { events },
      profileState: { contact, customerType },
      contractState: { contracts, selectedContractNumber }
    }: Props = this.props;
    const data = events && events.length > 0 ? events : [];
    return (
      <WrappedEvent
        data={data}
        // Banner
        title={Wording.title}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        selectedContractNumber={selectedContractNumber}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(EventPage);
