import { combineEpics } from 'redux-observable';

import { fetchLoginEpic, resetPasswordEpic } from '../_actions/auth.actions';
import {
  fetchContextEpic,
  updateCustomerEpic,
  updateBillingEpic,
  updatePasswordEpic,
  createSponsorEpic
} from '../_actions/profile.actions';
import { fetchContractEpic } from '../_actions/contract.actions';
import { fetchEventEpic, createEventEpic } from '../_actions/event.actions';
import {
  fetchMeterEpic,
  createIndexEpic
} from '../_actions/consumption.actions';
import { fetchFileEpic } from '../_actions/file.actions';
import {
  fetchPiecesEpic,
  createPaymentEpic,
  fetchBalanceEpic
} from '../_actions/payment.actions';
import {
  fetchPackageEpic,
  fetchProductEpic,
  fetchOfferEpic,
  updatePackageEpic
} from '../_actions/package.actions';

export default combineEpics(
  fetchLoginEpic,
  resetPasswordEpic,
  fetchContextEpic,
  fetchContractEpic,
  fetchEventEpic,
  createEventEpic,
  updateCustomerEpic,
  updateBillingEpic,
  fetchMeterEpic,
  createIndexEpic,
  updatePasswordEpic,
  createSponsorEpic,
  fetchFileEpic,
  fetchPiecesEpic,
  createPaymentEpic,
  fetchBalanceEpic,
  fetchPackageEpic,
  fetchProductEpic,
  fetchOfferEpic,
  updatePackageEpic
);
