import React from 'react';

import { TPrepaymentFrequency } from 'Models';

import { CustomRadio } from '../../components';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PackagePage;
const CommonWording = WordingConstant.Common;

export type Props = {
  loading: boolean;
  chosenFrequency: string;
  prepaymentFrequencies: TPrepaymentFrequency[];
  onChange: any;
};

const frequencyWording: { [key: string]: string } = CommonWording.frequency;

const FrequencyContainer: React.FC<Props> = ({
  loading,
  chosenFrequency,
  prepaymentFrequencies,
  onChange
}) => {
  return (
    <div className="frequency-container">
      <p className="frequency-container__title">
        {Wording.plan.frequency.title}
      </p>

      <div className="frequency-container__frequency">
        <p className="frequency-container__frequency__title">
          {Wording.plan.frequency.subtitle}
        </p>
        <div className="frequency-container__frequency__radios">
          {prepaymentFrequencies ? (
            prepaymentFrequencies.map(({ frequency, fee, currency }, index) => {
              const label = frequencyWording[frequency];
              return (
                <CustomRadio
                  key={index}
                  text={label}
                  value={frequency}
                  id={`id__${index}__${frequency}`}
                  description={`${fee}${currency}`}
                  checked={chosenFrequency === frequency}
                  handleCheck={onChange}
                />
              );
            })
          ) : (
            <p>{loading ? CommonWording.isLoading : CommonWording.noData}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FrequencyContainer;
