import React from 'react';

import { TWordingItem } from 'Models';
import { CustomSelect, CustomTextarea, CustomButton } from '../../components';

import WordingConstant from '../../utils/wording.json';
const Wording: { [key: string]: any } = WordingConstant.FormPage;

export type Props = {
  fields: {
    category: string;
    subCategory: string;
    message: string;
  };
  handleChangeTextarea: any;
  handleChangeSelect(item: TWordingItem, type: string): void;
  handleShowModal(section: string): void;
};

type State = {
  error: boolean;
};
class FormContainer extends React.Component<Props, State> {
  state = {
    error: false
  };

  render() {
    const {
      fields,
      handleChangeSelect,
      handleChangeTextarea,
      handleShowModal
    } = this.props;
    const { error } = this.state;
    const canSend: boolean =
      fields.category !== '' &&
      fields.subCategory !== '' &&
      fields.message !== '';

    const categoryOption = Wording.categories.find(
      (c: TWordingItem) => c.value === fields.category
    );
    const subCategoryOption =
      fields.subCategory !== '' &&
      Wording.subCategory[fields.category] &&
      Wording.subCategory[fields.category].find(
        (c: TWordingItem) => c.value === fields.subCategory
      );

    return (
      <div className="form-container">
        <div className="form-container__cart">
          <h2>{Wording.cart.question}</h2>
          <h3>{Wording.cart.question_2}</h3>
          <h3>{Wording.cart.question_3}</h3>
          <p>{Wording.cart.number}</p>
          <span>{Wording.cart.priceCall}</span>
          <p>{Wording.cart.email}</p>
        </div>

        <h2>{Wording.applicationFormText}</h2>

        <h4 className="form-container__statusText">
          {Wording.statusText} <span>{Wording.statusText_span}</span>
        </h4>

        <p>{Wording.categoryTitle}</p>
        <CustomSelect
          id="idCategory"
          value={fields.category !== '' ? categoryOption : ''}
          handleChangeSelect={(val: TWordingItem) =>
            handleChangeSelect(val, 'category')
          }
          options={Wording.categories}
          placeholder={Wording.placeholder}
          error={
            error && fields.category === '' ? Wording.error.category : null
          }
        />
        <p>{Wording.subCategoryTitle}</p>

        <CustomSelect
          id="idSubCategory"
          value={subCategoryOption}
          handleChangeSelect={(val: TWordingItem) =>
            handleChangeSelect(val, 'subCategory')
          }
          options={Wording.subCategory[fields.category]}
          placeholder={Wording.placeholder}
          error={
            error && fields.subCategory === ''
              ? Wording.error.subCategory
              : null
          }
        />

        <CustomTextarea
          id="idTextarea"
          placeholder={Wording.placeholderTextarea}
          text={Wording.message}
          onChange={handleChangeTextarea}
          value={fields.message}
          error={error && fields.message === '' ? Wording.error.message : null}
        />

        <CustomButton
          onClick={() => {
            if (canSend) {
              handleShowModal('');
            } else {
              this.setState({ error: true });
            }
          }}
          color={canSend ? 'orange' : 'gray'}
          title={Wording.button}
        />
      </div>
    );
  }
}

export default FormContainer;
