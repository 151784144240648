import { Epic } from 'redux-observable';
import { of, throwError, forkJoin } from 'rxjs';
import { mergeMap, catchError, filter, switchMap } from 'rxjs/operators';
import {
  createAsyncAction,
  createStandardAction,
  isActionOf,
  ActionType
} from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';
import { TPiece, TResponse } from 'Models';

import {
  FETCH_PIECES_FAILURE,
  FETCH_PIECES_SUCCESS,
  FETCH_PIECES_REQUEST,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_RESET,
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_FAILURE
} from './actionTypes';

interface IPiecesRequest {
  customerNbr: string;
  contractNbr: string;
}
interface ICreatePaymentRequest {
  pieceId: string;
  pieceDate: string;
  pieceType: string;
  executionDate: string;
  customerNbr: string;
  contractNbr: string;
}

interface ICreatePieceResponse {
  code: string;
  message: string;
  customerNbr?: string;
  contractNbr?: string;
}

interface IBalanceRequest {
  customerNbr: string;
  contractNbr: string;
  invoiceNbr?: string;
  mode?: string;
}

interface IBalanceResponse {
  customerBalance: string;
  contractBalance: string;
  invoiceBalance: string;
  code?: string;
  message?: string;
}

const resetUpdate = createStandardAction(CREATE_PAYMENT_RESET)();

const fetchPiecesAsync = createAsyncAction(
  FETCH_PIECES_REQUEST,
  FETCH_PIECES_SUCCESS,
  FETCH_PIECES_FAILURE
)<IPiecesRequest, TPiece[], TResponse>();

const createPaymentAsync = createAsyncAction(
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE
)<ICreatePaymentRequest, string, TResponse>();

const fetchBalanceAsync = createAsyncAction(
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_FAILURE
)<IBalanceRequest, IBalanceResponse, IBalanceResponse>();

export type PiecesAction =
  | ActionType<typeof createPaymentAsync>
  | ActionType<typeof fetchPiecesAsync>
  | ActionType<typeof resetUpdate>
  | ActionType<typeof fetchBalanceAsync>;

const prepareBalancePayload = ({
  contractNbr,
  customerNbr
}: {
  contractNbr: string;
  customerNbr: string;
}): IBalanceRequest => {
  return {
    contractNbr,
    customerNbr,
    mode: 'CONTRACT_BALANCE'
  };
};

const mapGetBalance = (action: RootAction, { apiRequest }: Services) => {
  const payload = prepareBalancePayload(action.payload);
  return apiRequest<IBalanceResponse>({
    path: '/getBalance',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: IBalanceResponse) => {
      if (response) return of(fetchBalanceAsync.success(response));
      return throwError({ code: '200', message: response });
    }),
    catchError(error => of(fetchBalanceAsync.failure(error)))
  );
};

const fetchBalanceEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(fetchBalanceAsync.request)),
    mergeMap(action => mapGetBalance(action, dependency))
  );

const preparePiecesPayload = ({
  contractNbr,
  customerNbr
}: {
  contractNbr: string;
  customerNbr: string;
}) => {
  return {
    contractNbr,
    customerNbr
  };
};

const mapGetPiece = (action: RootAction, { apiRequest }: Services) => {
  const payload = preparePiecesPayload(action.payload);
  return apiRequest<TPiece[]>({
    path: '/getFinancialPieces',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: TPiece[]) => {
      if (response) return of(fetchPiecesAsync.success(response));
      return throwError({ message: response });
    }),
    catchError(error => of(fetchPiecesAsync.failure(error)))
  );
};

const fetchPiecesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(fetchPiecesAsync.request)),
    mergeMap(action => mapGetPiece(action, dependency))
  );

const prepareCreatePaymentPayload = (request: ICreatePaymentRequest) => {
  return {
    pieceId: request.pieceId,
    pieceDate: request.pieceDate,
    pieceType: request.pieceType,
    executionDate: request.executionDate
  };
};

const mapCreatePayment = (action: RootAction, { apiRequest }: Services) => {
  const payload = prepareCreatePaymentPayload(action.payload);
  return apiRequest<any>({
    path: '/createPayment',
    method: 'post',
    body: payload
  }).pipe(
    switchMap((response: any) => {
      if (response) {
        return of({
          code: '200',
          message: 'success',
          customerNbr: action.payload.customerNbr,
          contractNbr: action.payload.contractNbr
        });
      }
      return throwError({ code: '200', message: response });
    }),
    catchError(error => of(error))
  );
};

const createPaymentEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(createPaymentAsync.request)),
    switchMap(
      (action: RootAction) => mapCreatePayment(action, dependency),
      (action: RootAction, r: ICreatePieceResponse) => [action, r]
    ),
    switchMap(([action, createPieceResponse]) => {
      const newAction = {
        ...action,
        payload: {
          ...action.payload,
          customerNbr: action.payload.customerNbr,
          contractNbr: action.payload.contractNbr
        }
      };

      if (createPieceResponse.code === '200') {
        return forkJoin(
          of(createPieceResponse),
          of(mapGetPiece(newAction, dependency))
        );
      }
      return forkJoin(of(createPieceResponse));
    }),
    switchMap(([createPieceResponse, pieceResponse]) => {
      if (pieceResponse) {
        // return of(fetchPiecesAsync.success(pieceResponse));
      }
      return of(createPaymentAsync.failure(createPieceResponse));
    }),
    catchError(error => of(createPaymentAsync.failure(error)))
  );

export {
  mapGetPiece,
  fetchPiecesAsync,
  fetchPiecesEpic,
  createPaymentEpic,
  mapCreatePayment,
  createPaymentAsync,
  resetUpdate,
  mapGetBalance,
  fetchBalanceAsync,
  fetchBalanceEpic
};
