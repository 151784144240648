import React from 'react';

import { InputEvent } from 'Types';
import { TAdditionalRate } from 'Models';
import CustomToggle from '../CustomToggle';
import { IPayloadUpdatePackage as PackagePayload } from '../../_actions/package.actions';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';
import { EModeManageOffer } from '../../utils/enums';

const Wording = WordingConstant.PackagePage;

type Props = {
  idToggle: string;
  options: TAdditionalRate[];
  currentOptions: string[];
  handleToggle(section: string, payload: PackagePayload): void;
};

const OptionElement: React.FC<Props> = ({
  options,
  currentOptions,
  handleToggle,
  idToggle
}) => (
  <div className="option-element">
    <h1> {Wording.option.title}</h1>
    <div className="option-element__content">
      {options &&
        options.map((option, index) => (
          <div key={index} className="option-element__content__item">
            <div className="option-element__content__item__toggle">
              <CustomToggle
                id={`idOption__${idToggle}__${index}`}
                text={option.label}
                value={option.label}
                checked={currentOptions.includes(option.label)}
                handleCheck={(e: InputEvent) => {
                  handleToggle('option', {
                    option: {
                      label: option.label,
                      active: e.currentTarget.checked
                    },
                    mode: EModeManageOffer.OPTIONS
                  });
                }}
              />
              <img src={resources['ic-elec-green']} alt="ic-elec" />
            </div>
            {option.description && (
              <p className="option-element__content__item__description">
                {option.description}
              </p>
            )}
          </div>
        ))}
    </div>
  </div>
);

export default OptionElement;
