import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';

import {
  fetchContractAsync,
  resetContractState
} from '../../_actions/contract.actions';
import {
  fetchPiecesAsync,
  createPaymentAsync,
  resetUpdate
} from '../../_actions/payment.actions';

import withBanner, {
  ExternalProps as ExternalPropsBanner
} from '../../libraries/withBanner';
import withModal, {
  ExternalProps as ExternalPropsModal,
  InjectedProps as InjectedPropsModal
} from '../../libraries/withModal';
import HomeContainer, {
  Props as ContainerProps
} from '../../containers/HomeContainer/HomeContainer';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.HomePage;

const WrappedHome = compose<
  ContainerProps,
  Subtract<ContainerProps, InjectedPropsModal> &
    ExternalPropsBanner &
    ExternalPropsModal
>(
  withBanner(),
  withModal()
)(HomeContainer);

const mapStateToProps = (state: RootState) => ({
  profileState: state.profileReducer,
  contractState: state.contractReducer,
  paymentState: state.paymentReducer
});

const dispatchProps = {
  contractDispatch: fetchContractAsync.request,
  fetchPiecesDispatch: fetchPiecesAsync.request,
  createPaymentDispatch: createPaymentAsync.request,
  resetCreatePaymentDispatch: resetUpdate,
  resetContractDispatch: resetContractState
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

type State = {
  fields: IFields;
};

interface IFields {
  pieceId: string;
  pieceDate: string;
  pieceType: string;
  executionDate: string;
}

class HomePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: {
        pieceId: '',
        pieceDate: '',
        pieceType: '',
        executionDate: ''
      }
    };
  }
  componentDidMount = () => {
    const {
      contractState: { selectedContractNumber },
      fetchPiecesDispatch,
      profileState: { customerNumber }
    }: Props = this.props;

    fetchPiecesDispatch({
      contractNbr: selectedContractNumber,
      customerNbr: customerNumber
    });
  };

  handleChangeBanner = (contractNumber: string) => {
    const {
      contractDispatch,
      profileState: { customerNumber },
      fetchPiecesDispatch
    } = this.props;
    const payload = {
      customerNbr: customerNumber,
      contractNbr: contractNumber
    };
    contractDispatch(payload);
    fetchPiecesDispatch(payload);
  };

  handleChangeFildPieces = ({
    pieceId,
    executionDate,
    pieceDate,
    pieceType
  }: {
    pieceId: string;
    pieceDate: string;
    pieceType: string;
    executionDate: string;
  }) => {
    const { fields } = this.state;

    fields.executionDate = executionDate;
    fields.pieceDate = pieceDate;
    fields.pieceId = pieceId;
    fields.pieceType = pieceType;

    this.setState({ fields });
  };

  handleUpdate = () => {
    const { fields } = this.state;
    const {
      contractState,
      profileState: { customerNumber },
      createPaymentDispatch
    } = this.props;
    createPaymentDispatch({
      ...fields,
      customerNbr: customerNumber,
      contractNbr: contractState.selectedContractNumber
    });

    fields.executionDate = '';
    fields.pieceDate = '';
    fields.pieceId = '';
    fields.pieceType = '';
    this.setState({ fields });
  };

  render() {
    const { fields } = this.state;
    const {
      profileState: { contact, customerType },
      contractState: { contracts, selectedContractNumber, error },
      paymentState: { pieces, updateResponse },
      resetCreatePaymentDispatch,
      resetContractDispatch
    }: Props = this.props;

    return (
      <WrappedHome
        fields={fields}
        pieces={pieces}
        handleChangeFildPieces={this.handleChangeFildPieces}
        // Banner
        title={Wording.title}
        bannerError={error}
        handleCloseModalBanner={() => resetContractDispatch()}
        selectedContractNumber={selectedContractNumber}
        contracts={contracts}
        contact={contact}
        customerType={customerType}
        handleChangeBanner={this.handleChangeBanner}
        // Modal
        modalMessage={Wording.popup}
        updateResponse={updateResponse}
        handleValidate={this.handleUpdate}
        handleReset={resetCreatePaymentDispatch}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(HomePage);
