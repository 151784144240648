import React from 'react';

import { InputEvent } from 'Types';

import { CustomCheckbox, CustomInput, CustomButton } from '../../components';
import { isValidPassword } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.SettingPage;

interface IFields {
  toggles: IToggles;
  password: IPassword;
}

interface IPassword {
  old: string;
  new: string;
  confirm: string;
}

interface IToggles {
  isReceiveNews: boolean;
  isAuthorizeData: boolean;
}
interface IErrors {
  showNewPass: boolean;
  showConfirmPass: boolean;
}

export type Props = {
  fields: IFields;
  errors: IErrors;
  handleToggle: any;
  handleInput: any;
  handleError: any;
  handleShowModal(section: string): void;
};

const SettingContainer: React.FC<Props> = props => {
  const isNewValid = isValidPassword(props.fields.password.new);
  const isConfirmValid =
    props.fields.password.new === props.fields.password.confirm;

  const isValid =
    // props.fields.password.confirm !== '' &&
    // props.fields.password.new !== '' &&
    props.fields.password.old !== '' && isNewValid && isConfirmValid;

  return (
    <div className="setting-container">
      <h2 className="setting-container__subTitle">{Wording.subTitle}</h2>
      <p className="setting-container__toggle-title">{Wording.toggle.title}</p>
      <CustomCheckbox
        checked={props.fields.toggles.isReceiveNews}
        text={Wording.toggle.isReceiveNews}
        handleCheck={() => props.handleToggle('isReceiveNews')}
      />
      <CustomCheckbox
        text={Wording.toggle.isAuthorizeData}
        checked={props.fields.toggles.isAuthorizeData}
        handleCheck={() => props.handleToggle('isAuthorizeData')}
      />

      <CustomButton
        title={Wording.buttonValue}
        color={'orange'}
        onClick={() => props.handleShowModal('customer')}
      />
      <h2 className="setting-container__password-title">
        {Wording.password.title}
      </h2>
      <p className="setting-container__password-subTitle">
        {Wording.password.subTitle}
      </p>
      <div className="setting-container__password-inputs">
        <span className="setting-container__password-inputs__title">
          {Wording.password.old}
        </span>
        <CustomInput
          type="password"
          value={props.fields.password.old}
          onInputChange={(e: InputEvent) => props.handleInput(e, 'old')}
          placeholder={Wording.password.placeholder}
        />
        <span className="setting-container__password-inputs__title">
          {Wording.password.new}
        </span>
        <CustomInput
          type="password"
          value={props.fields.password.new}
          onInputChange={(e: InputEvent) => props.handleInput(e, 'new')}
          placeholder={Wording.password.placeholder}
          onBlur={() => props.handleError('showNewPass', !isNewValid)}
          error={
            props.errors.showNewPass ? Wording.password.error.new : undefined
          }
        />
        <span className="setting-container__password-inputs__title">
          {Wording.password.confirm}
        </span>
        <CustomInput
          type="password"
          value={props.fields.password.confirm}
          onInputChange={(e: InputEvent) => props.handleInput(e, 'confirm')}
          placeholder={Wording.password.placeholder}
          onBlur={() => props.handleError('showConfirmPass', !isConfirmValid)}
          error={
            props.errors.showConfirmPass
              ? Wording.password.error.confirm
              : undefined
          }
        />
      </div>
      <CustomButton
        title={Wording.buttonValue1}
        color={isValid ? 'orange' : 'gray'}
        onClick={() => (isValid ? props.handleShowModal('password') : null)}
      />
    </div>
  );
};

export default SettingContainer;
