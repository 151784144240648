import {
  TContract,
  TCustomerFullName,
  TAddress,
  TContact,
  TFinance,
  TFile,
  TPiece,
  TAmountWithLabel,
  TUnitPriceWithLabel,
  TProduct,
  TPackage
} from 'Models';
import {
  EAmountCategory,
  ERateOption,
  EEnergy,
  EAmountType,
  EContractStatus,
  ECivility,
  EFrequency,
  EEffectiveStartRange,
  EReadingFrequency,
  ETimeframe,
  EBillingMode,
  EProcessType,
  EAppointmentTimeslot,
  EInvoiceRoutingMode
} from './enums';
import { getTodayDate } from './helpers';

export const initialCustomerFullName: TCustomerFullName = {
  civility: 'MR',
  firstName: '',
  lastName: ''
};

export const initialAddress: TAddress = {
  country: 'FR',
  netArea: '',
  number: '',
  postalCode: '',
  street: '',
  townName: ''
};

export const initialContact: TContact = {
  customerFullName: {
    civility: ECivility.MR,
    firstName: '',
    lastName: ''
  },
  birthdate: '',
  address: {
    number: '',
    street: '',
    netArea: '',
    postalCode: '',
    townName: '',
    country: 'FR'
  },
  email: '',
  phone: '',
  companyName: '',
  companyType: '',
  identificationNumber: '',
  activityCode: '',
  newsletter: false,
  marketing: false
};

export const initialFinance: TFinance = {
  paymentMode: EBillingMode.PAYMENT_SCHEDULE,
  ibanCode: '',
  bicCode: '',
  bankName: '',
  accountOwnerFirstName: '',
  accountOwnerLastName: '',
  rumCode: '',
  invoiceRoutingMode: EInvoiceRoutingMode.EMAIL,
  invoiceEmail: '',
  debitDay: '',
  termCode: ''
};

export const initialFile: TFile = {
  documentDate: '',
  documentKey: '',
  documentName: '',
  documentType: ''
};

export const initialPieces: TPiece = {
  pieceAmount: '',
  pieceBalance: '',
  pieceCurrency: '',
  pieceDate: '',
  pieceDueDate: '',
  pieceId: '',
  pieceStatus: '',
  pieceType: ''
};

export const initialAmountWithLabel: TAmountWithLabel = {
  label: '',
  amount: '',
  unit: '',
  category: EAmountCategory.ADDITIONAL_RATE,
  unitPrices: []
};

export const initialUnitPriceWithLabel: TUnitPriceWithLabel = {
  label: '',
  price: '',
  unit: ''
};

export const initialProduct: TProduct = {
  productCode: '',
  productType: '',
  label: '',
  description: '',
  energy: EEnergy.EL,
  default: true,
  rateOption: undefined,
  additionalRates: [
    {
      code: undefined,
      label: '',
      description: undefined,
      type: EAmountType.OPTION,
      detail: {
        label: '',
        price: '',
        unit: ''
      }
    }
  ]
};

const today = getTodayDate();

export const initialContract: TContract = {
  contractNumber: '',
  contractStatus: EContractStatus.EFFECTIVE,
  channel: 'WEB',
  effectiveStartRange: EEffectiveStartRange.NOW,
  effectiveStartDate: today,
  subscriptionDate: today,
  sponsorNumber: '',
  energy: EEnergy.EL,
  contractCategory: '',
  deliveryCategoryCode: '',
  deliveryPoint: {
    pointOfDelivery: '',
    deliveryPointCategory: '',
    grdCode: '',
    state: '',
    gridRate: '',
    deliveryStatus: '',
    address: {
      number: '',
      street: '',
      netArea: '',
      postalCode: '',
      townName: '',
      country: 'FR'
    },
    profile: '',
    meterNumber: '',
    meterType: '',
    smartMeterStatus: '',
    netArea: '',
    readingFrequency: EReadingFrequency.MONTHLY,
    contractualCapacity: 0,
    contractualCapacityUnit: '',
    process: {
      type: EProcessType.MOVE_IN,
      express: false,
      supplierCalendar: '',
      appointmentTimeslot: EAppointmentTimeslot.NONE,
      index: {
        type: '',
        date: '',
        registers: [
          {
            value: 0,
            timeframe: ETimeframe.UNKNOWN
          }
        ]
      }
    }
  },
  billingMode: EBillingMode.PAYMENT_SCHEDULE,
  installmentFrequency: EFrequency.BIMONTHLY,
  settlementFrequency: EFrequency.BIANNUAL,
  finance: {
    paymentMode: EBillingMode.PAYMENT_SCHEDULE,
    ibanCode: '',
    bicCode: '',
    bankName: '',
    accountOwnerLastName: '',
    accountOwnerFirstName: '',
    billingContact: undefined,
    rumCode: '',
    invoiceRoutingMode: EInvoiceRoutingMode.EMAIL,
    invoiceEmail: '',
    debitDay: '',
    termCode: ''
  },
  thirdParties: [],
  rateOption: ERateOption.UNKNOWN,
  chosenProduct: initialProduct
};

export const initialPackage: TPackage = {
  id: '',
  amount: '',
  energy: EEnergy.EL,
  currency: '',
  quantityMonthly: 0,
  packageQuantity: 0,
  recommended: false,
  timeframe: ETimeframe.UNKNOWN,
  authorizedPackages: undefined
};
