import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '../_actions/auth.actions';
import * as contractActions from '../_actions/contract.actions';
import * as profileActions from '../_actions/profile.actions';
import * as eventActions from '../_actions/event.actions';
import * as consumptionActions from '../_actions/consumption.actions';
import * as fileActions from '../_actions/file.actions';
import * as paymentActions from '../_actions/payment.actions';
import * as packageActions from '../_actions/package.actions';

export type AuthState = Readonly<{
  loading: boolean;
  error?: string;
  token?: string;
  userId?: string;
  unauthorized: boolean;
}>;

export const initialState: AuthState = {
  loading: false,
  error: undefined,
  token: undefined,
  userId: undefined,
  unauthorized: false
};

type Actions =
  | actions.AuthAction
  | contractActions.ContractAction
  | profileActions.ProfileAction
  | eventActions.EventAction
  | consumptionActions.ConsumptionAction
  | fileActions.FileActions
  | paymentActions.PiecesAction
  | packageActions.PackageAction;

const authReducer: Reducer<AuthState, Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getType(actions.fetchLoginAsync.request):
    case getType(actions.resetPasswordAsync.request): {
      return {
        ...state,
        loading: true,
        error: undefined
      };
    }

    case getType(actions.fetchLoginAsync.failure): {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }

    case getType(actions.fetchLoginAsync.success): {
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        userId: action.payload.userId
      };
    }

    case getType(actions.resetPasswordAsync.failure): {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case getType(actions.resetPasswordAsync.success): {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case getType(contractActions.fetchContractAsync.request):
    case getType(eventActions.createEventAsync.request):
    case getType(eventActions.fetchEventAsync.request):
    case getType(consumptionActions.fetchMeterConfigAsync.request):
    case getType(consumptionActions.fetchMeterReadingAsync.request):
    case getType(consumptionActions.createIndexAsync.request):
    case getType(profileActions.fetchContextAsync.request):
    case getType(profileActions.updatePasswordAsync.request):
    case getType(profileActions.updateCustomerAsync.request):
    case getType(profileActions.updateBillingAsync.request):
    case getType(fileActions.fetchFilesListAsync.request):
    case getType(paymentActions.createPaymentAsync.request):
    case getType(paymentActions.fetchPiecesAsync.request):
    case getType(profileActions.createSponsorAsync.request):
    case getType(packageActions.fetchPackageAsync.request):
    case getType(packageActions.fetchProductAsync.request):
    case getType(packageActions.updatePackageAsync.request): {
      return {
        ...state,
        loading: true
      };
    }

    case getType(contractActions.fetchContractAsync.success):
    case getType(eventActions.fetchEventAsync.success):
    case getType(eventActions.createEventAsync.success):
    case getType(consumptionActions.fetchMeterConfigAsync.success):
    case getType(consumptionActions.fetchMeterReadingAsync.success):
    case getType(consumptionActions.createIndexAsync.success):
    case getType(profileActions.fetchContextAsync.success):
    case getType(profileActions.updatePasswordAsync.success):
    case getType(profileActions.updateCustomerAsync.success):
    case getType(profileActions.updateBillingAsync.success):
    case getType(profileActions.createSponsorAsync.success):
    case getType(fileActions.fetchFilesListAsync.success):
    case getType(paymentActions.createPaymentAsync.success):
    case getType(paymentActions.fetchPiecesAsync.success):
    case getType(packageActions.fetchPackageAsync.success):
    case getType(packageActions.fetchProductAsync.success):
    case getType(packageActions.updatePackageAsync.success): {
      return {
        ...state,
        loading: false
      };
    }

    case getType(packageActions.fetchProductAsync.failure):
    case getType(packageActions.fetchPackageAsync.failure):
    case getType(packageActions.updatePackageAsync.failure):
    case getType(profileActions.updateCustomerAsync.failure):
    case getType(profileActions.updateBillingAsync.failure):
    case getType(profileActions.fetchContextAsync.failure):
    case getType(profileActions.updatePasswordAsync.failure):
    case getType(profileActions.createSponsorAsync.failure):
    case getType(paymentActions.createPaymentAsync.failure):
    case getType(paymentActions.fetchPiecesAsync.failure):
    case getType(eventActions.fetchEventAsync.failure):
    case getType(eventActions.createEventAsync.failure):
    case getType(contractActions.fetchContractAsync.failure):
    case getType(consumptionActions.createIndexAsync.failure):
    case getType(consumptionActions.fetchMeterConfigAsync.failure):
    case getType(consumptionActions.fetchMeterReadingAsync.failure):
    case getType(fileActions.fetchFilesListAsync.failure): {
      const { code } = action.payload;
      return {
        ...state,
        loading: false,
        unauthorized: code !== undefined && code === '401'
      };
    }

    default:
      return state;
  }
};

export default authReducer;
