import React from 'react';

type Props = {
  title?: string;
  color?: string;
  onClick?: any;
  arrowLeft?: boolean;
  arrowRight?: boolean;
  isLoading?: boolean;
};

const CustomButton: React.FC<Props> = ({
  title,
  color,
  onClick,
  arrowLeft,
  arrowRight,
  isLoading
}) => (
  <button
    className={`custom-button custom-button--${color}  ${
      isLoading ? 'custom-button__loading' : ''
    }`}
    type="button"
    onClick={onClick}
  >
    {arrowLeft && <i className="custom-button__arrowLeft" />}
    {title}
    {arrowRight && <i className="custom-button__arrowRight" />}
  </button>
);

export default CustomButton;
