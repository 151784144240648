import { Epic } from 'redux-observable';
import { of, forkJoin, throwError } from 'rxjs';
import { mergeMap, catchError, filter, switchMap } from 'rxjs/operators';
import {
  createAsyncAction,
  createStandardAction,
  isActionOf,
  ActionType,
  getType
} from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import { mapGetContext, fetchContextAsync } from './profile.actions';

import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  REDUX_RESET_STATE
} from './actionTypes';
import Config from '../utils/config';

interface IPayload {
  user?: string;
  password?: string;
  email?: string;
  userId?: string;
  token?: string;
  accessToken?: string;
  error?: string;
  message?: string;
}

const fetchLoginAsync = createAsyncAction(
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE
)<IPayload, IPayload, IPayload>();

const resetPasswordAsync = createAsyncAction(
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
)<IPayload, string, string>();

const resetReduxStore = createStandardAction(REDUX_RESET_STATE)();

export type AuthAction =
  | ActionType<typeof fetchLoginAsync>
  | ActionType<typeof resetPasswordAsync>
  | ActionType<typeof resetReduxStore>;

const preparePayloadLogin = ({ user, password }: IPayload) => {
  return {
    user,
    password
  };
};

const mapPostLogin = (action: RootAction, { apiRequest }: Services) => {
  const payload = preparePayloadLogin(action.payload);
  return apiRequest<IPayload>({
    path: '/login',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: IPayload) => {
      if (response && response.token) {
        const user = {
          userId: response.user,
          token: response.token,
          accessToken: response.accessToken
        };
        if (user.token && user.accessToken) {
          localStorage.setItem('token', user.token);
          localStorage.setItem('accessToken', user.accessToken);
          const dt = String(
            new Date().getTime() + 1000 * Config.ExpiredSessionTimeout
          );
          localStorage.setItem('expires', dt);
        }
        return of(user);
      }
      const message = response.message || 'Une erreur est survenue';
      return throwError({ message });
    }),
    catchError(error => {
      return of({ error: error.message });
    })
  );
};

const fetchLoginEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(fetchLoginAsync.request)),
    switchMap(
      (action: RootAction) => mapPostLogin(action, dependency),
      (action: RootAction, r: IPayload) => [action, r]
    ),
    switchMap(([action, loginResponse]) => {
      if (loginResponse.error) {
        return forkJoin(
          of(loginResponse),
          of(fetchContextAsync.failure({ code: '500', message: '' }))
        );
      }
      const modifiedAction = {
        ...action,
        payload: {
          ...action.payload,
          customerNbr: loginResponse.userId
        }
      };
      return forkJoin(
        of(loginResponse),
        mapGetContext(modifiedAction, dependency)
      );
    }),
    switchMap(([loginResponse, contextResponse]) => {
      if (
        loginResponse &&
        loginResponse.token &&
        contextResponse.type === getType(fetchContextAsync.success)
      ) {
        if (loginResponse.token && loginResponse.userId) {
          localStorage.setItem('userId', loginResponse.userId);
        }
        return of(
          fetchLoginAsync.success(loginResponse),
          fetchContextAsync.success(contextResponse.payload)
        );
      }
      const message = loginResponse.error || 'Une erreur est survenue';
      return of(
        fetchLoginAsync.failure({
          error: message
        })
      );
    }),
    catchError(error => {
      return of(
        fetchLoginAsync.failure({
          error: error.message
        })
      );
    })
  );

const preparePayloadResetPassword = ({ email }: IPayload) => {
  return {
    customerNbr: email
  };
};

const mapPostResetPassword = (action: RootAction, { apiRequest }: Services) => {
  const payload = preparePayloadResetPassword(action.payload);
  return apiRequest<IPayload>({
    path: '/resetPassword',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: IPayload) => {
      if (response.message || response === '200') {
        return of(resetPasswordAsync.success(response.message || ''));
      }
      return of(resetPasswordAsync.failure('Une erreur est survenue'));
    }),
    catchError(error => {
      return of(resetPasswordAsync.failure(error.message));
    })
  );
};

const resetPasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(resetPasswordAsync.request)),
    mergeMap(action => mapPostResetPassword(action, dependency))
  );

export {
  fetchLoginEpic,
  fetchLoginAsync,
  resetPasswordAsync,
  resetPasswordEpic,
  resetReduxStore
};
