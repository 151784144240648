import React from 'react';

import { TPiece } from 'Models';

import { getFormattedDate, findNextAndLastPiece } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PaymentStatusContainer;

type Props = {
  pieces?: TPiece[];
};

const PaymentStatusContainer: React.FC<Props> = ({ pieces }) => {
  const data = findNextAndLastPiece(pieces);
  return (
    <div className="payment-status-container">
      <div className="payment-status-container__block">
        <p>{Wording.lastPayment}</p>
        <div className="payment-status-container__block__data">
          {pieces && data && data.last ? (
            <React.Fragment>
              <h3>{`${data.last.pieceAmount} €`}</h3>
              <p className="payment-status-container__block__data__date">{`le ${getFormattedDate(
                data.last.pieceDate
              )}`}</p>
            </React.Fragment>
          ) : (
            <p className="payment-status-container__block__data__none">
              {Wording.noneData}
            </p>
          )}
        </div>
      </div>
      <div className="payment-status-container__block">
        <p>{Wording.nextPayment}</p>
        <div className="payment-status-container__block__data">
          {pieces && data && data.next ? (
            <React.Fragment>
              <h3>{`${data.next.pieceAmount} €`}</h3>
              <p className="payment-status-container__block__data__date">{`le ${getFormattedDate(
                data.next.pieceDate
              )}`}</p>
            </React.Fragment>
          ) : (
            <p className="payment-status-container__block__data__none">
              {Wording.noneData}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusContainer;
