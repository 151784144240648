import { Epic } from 'redux-observable';
import { of, forkJoin, Observable, throwError } from 'rxjs';
import { mergeMap, switchMap, catchError, filter } from 'rxjs/operators';
import {
  createAsyncAction,
  createStandardAction,
  isActionOf,
  ActionType
} from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';
import {
  TPackage,
  TPrepaymentFrequency,
  TContract,
  TProduct,
  TOffer,
  TResponse
} from 'Models';
import {
  EEnergy,
  ECustomerType,
  EModeManageOffer,
  EFrequency
} from '../utils/enums';
import {
  FETCH_PACKAGE_REQUEST,
  FETCH_PACKAGE_SUCCESS,
  FETCH_PACKAGE_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_OFFER_REQUEST,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFER_FAILURE,
  UPDATE_PACKAGE_REQUEST,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILURE,
  UPDATE_PACKAGE_RESET
} from './actionTypes';
import { getTodayDate } from '../utils/helpers';

interface IPayloadPackage {
  contractNbr: string;
  customerType?: ECustomerType;
  energy?: EEnergy;
  reference?: string;
  packagesList?: TPackage[];
  prepaymentFrequencies?: TPrepaymentFrequency[];
  error?: string;
}

interface IPayloadProduct {
  customerType?: ECustomerType;
  contracts?: TContract[];
  productsList?: TProduct[];
  error?: string;
}

interface IPayloadOffer {
  customerType?: ECustomerType;
  contracts?: TContract[];
  offers?: TOffer[];
  contractNbr?: string;
  error?: string;
  code?: string;
  message?: string;
}

export interface IPayloadUpdatePackage {
  customerType?: string;
  customerNbr?: string;
  contract?: TContract;
  mode?: EModeManageOffer;
  chosenPackages?: TPackage[];
  frequency?: EFrequency;
  product?: TProduct;
  option?: {
    label: string;
    active: boolean;
  };
  code?: string;
  message?: string;
  description?: string;
}

const resetUpdate = createStandardAction(UPDATE_PACKAGE_RESET)();

const fetchPackageAsync = createAsyncAction(
  FETCH_PACKAGE_REQUEST,
  FETCH_PACKAGE_SUCCESS,
  FETCH_PACKAGE_FAILURE
)<IPayloadPackage, IPayloadPackage, TResponse>();

const fetchProductAsync = createAsyncAction(
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE
)<IPayloadProduct, IPayloadProduct, TResponse>();

const fetchOfferAsync = createAsyncAction(
  FETCH_OFFER_REQUEST,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFER_FAILURE
)<IPayloadOffer, IPayloadOffer, TResponse>();

const updatePackageAsync = createAsyncAction(
  UPDATE_PACKAGE_REQUEST,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILURE
)<IPayloadUpdatePackage, IPayloadUpdatePackage, TResponse>();

export type PackageAction =
  | ActionType<typeof fetchPackageAsync>
  | ActionType<typeof fetchProductAsync>
  | ActionType<typeof fetchOfferAsync>
  | ActionType<typeof updatePackageAsync>
  | ActionType<typeof resetUpdate>;

const preparePayloadFetchPackage = ({
  energy,
  reference,
  customerType
}: IPayloadPackage) => {
  return {
    customerType,
    mode: 'POINT_OF_DELIVERY',
    pointOfDeliveryList: [
      {
        energy,
        reference
      }
    ]
  };
};

const mapFetchPackage = (action: RootAction, { apiRequest }: Services) => {
  const payload = preparePayloadFetchPackage(action.payload);
  return apiRequest<IPayloadPackage>({
    path: '/getPackages',
    method: 'post',
    body: payload,
    isSubscription: true
  }).pipe(
    mergeMap((response: IPayloadPackage) => {
      if (!response) {
        return of(
          fetchPackageAsync.failure({
            code: '500',
            message: 'Erreur serveur'
          })
        );
      }
      return of(
        fetchPackageAsync.success({
          packagesList: response.packagesList,
          prepaymentFrequencies: response.prepaymentFrequencies,
          contractNbr: action.payload.contractNbr
        })
      );
    }),
    catchError(error => of(fetchPackageAsync.failure(error)))
  );
};

const fetchPackageEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(fetchPackageAsync.request)),
    mergeMap(action => mapFetchPackage(action, dependency))
  );

const preparePayloadFetchOffer = ({
  contracts,
  customerType
}: IPayloadOffer) => {
  return {
    contracts,
    customerType
  };
};

const mapFetchOffer = (
  action: RootAction,
  { apiRequest }: Services
): Observable<IPayloadOffer> => {
  const payload = preparePayloadFetchOffer(action.payload);
  return apiRequest<IPayloadOffer>({
    path: '/getOffers',
    method: 'post',
    body: payload,
    isSubscription: true
  }).pipe(
    mergeMap((response: IPayloadOffer) => {
      if (response) {
        const contractNbr = action.payload.contracts[0].contractNumber;
        return of({
          contractNbr,
          offers: response.offers
        });
      }
      return of({
        error: 'failure'
      });
    }),
    catchError(error =>
      of({
        error: 'failure'
      })
    )
  );
};

const fetchOfferEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(fetchOfferAsync.request)),
    mergeMap(action => mapFetchOffer(action, dependency))
  );

const preparePayloadFetchProduct = ({
  customerType,
  contracts
}: IPayloadProduct) => {
  return {
    customerType,
    contracts,
    contextOfUse: 'MARKET'
  };
};

const mapFetchProduct = (
  action: RootAction,
  { apiRequest }: Services
): Observable<IPayloadProduct> => {
  const payload = preparePayloadFetchProduct(action.payload);
  return apiRequest<IPayloadProduct>({
    path: '/getProducts',
    method: 'post',
    body: payload,
    isSubscription: true
  }).pipe(
    mergeMap((response: IPayloadProduct) => {
      if (response.productsList) {
        return of({ productsList: response.productsList });
      }
      return of({
        error: 'failure',
        code: '200',
        message: 'Erreur serveur'
      });
    }),
    catchError(error =>
      of({
        error: error.message,
        code: error.code,
        message: error.message
      })
    )
  );
};

const fetchProductEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(fetchProductAsync.request)),
    switchMap(
      (action: RootAction) => mapFetchProduct(action, dependency),
      (action: RootAction, r: IPayloadProduct) => [action, r]
    ),
    switchMap(([action, productResponse]) => {
      if (productResponse.code === '401') {
        return throwError(productResponse);
      }
      return forkJoin(of(productResponse), mapFetchOffer(action, dependency));
    }),
    switchMap(([productResponse, offerResponse]) => {
      const promiseProduct = productResponse.error
        ? fetchProductAsync.failure({
            code: '500',
            message: 'Erreur serveur'
          })
        : fetchProductAsync.success({
            productsList: productResponse.productsList
          });
      const promiseOffer = offerResponse.error
        ? fetchOfferAsync.failure({
            code: '500',
            message: 'Erreur serveur'
          })
        : fetchOfferAsync.success({
            offers: offerResponse.offers,
            contractNbr: offerResponse.contractNbr
          });

      return of(promiseProduct, promiseOffer);
    }),
    catchError(error => {
      return of(fetchProductAsync.failure(error));
    })
  );

const preparePayloadUpdatePackage = ({
  customerNbr,
  contract,
  mode,
  product,
  chosenPackages,
  frequency,
  option
}: IPayloadUpdatePackage) => {
  if (contract === undefined) {
    return {
      customerNbr,
      mode,
      contract
    };
  }
  const modifiedContract: TContract = { ...contract };
  switch (mode) {
    case EModeManageOffer.INSTALLMENT_FREQUENCY: {
      if (frequency) {
        modifiedContract.installmentFrequency = frequency;
      }
      break;
    }

    case EModeManageOffer.PACKAGE: {
      if (chosenPackages) {
        modifiedContract.chosenPackages = chosenPackages;
      }
      break;
    }

    case EModeManageOffer.PRODUCT: {
      if (product) {
        modifiedContract.chosenProduct = product;
        modifiedContract.effectiveStartDate = getTodayDate();
      }
      break;
    }

    case EModeManageOffer.OPTIONS: {
      if (option) {
        let additionalRates = modifiedContract.chosenProduct.additionalRates;
        if (additionalRates) {
          additionalRates = additionalRates.map(r => {
            if (r.label !== option.label) {
              return r;
            }
            return {
              ...r,
              active: option.active,
              updated: true
            };
          });
        }
        modifiedContract.chosenProduct = {
          ...modifiedContract.chosenProduct,
          additionalRates
        };
      }
      break;
    }

    default:
      break;
  }
  return {
    customerNbr,
    mode,
    contract: modifiedContract
  };
};

const mapUpdatePackage = (action: RootAction, { apiRequest }: Services) => {
  const payload = preparePayloadUpdatePackage(action.payload);
  return apiRequest<IPayloadUpdatePackage>({
    path: '/manageOffer',
    method: 'post',
    body: payload
  }).pipe(
    mergeMap((response: IPayloadUpdatePackage) => {
      // Server might respond with code and description
      // It should always return code and message
      if (response && response.code === 'OK') {
        return of({
          code: response.code,
          contract: payload.contract
        });
      }
      if (response && response.code === 'KO') {
        return of({
          code: response.code,
          description: response.description
        });
      }

      return of({
        code: '500',
        message: 'Erreur serveur'
      });
    }),
    catchError(error => of(error))
  );
};

const updatePackageEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  dependency
) =>
  action$.pipe(
    filter(isActionOf(updatePackageAsync.request)),
    switchMap(
      (action: RootAction) => mapUpdatePackage(action, dependency),
      (action: RootAction, r: IPayloadUpdatePackage) => [action, r]
    ),
    switchMap(([action, packageResponse]) => {
      if (packageResponse.code === '401' || packageResponse.code === '500') {
        return throwError(packageResponse);
      }

      if (action.payload.mode === EModeManageOffer.PRODUCT) {
        const modifiedAction = {
          ...action,
          payload: {
            ...action.payload,
            contracts: [packageResponse.contract]
          }
        };
        return forkJoin(
          of(packageResponse),
          mapFetchOffer(modifiedAction, dependency)
        );
      }
      return forkJoin(of(packageResponse));
    }),
    switchMap(([packageResponse, offerResponse]) => {
      const promisePackage = updatePackageAsync.success(packageResponse);
      if (offerResponse) {
        const promiseOffer = offerResponse.error
          ? fetchOfferAsync.failure({
              code: '500',
              message: 'Erreur serveur'
            })
          : fetchOfferAsync.success({
              offers: offerResponse.offers,
              contractNbr: offerResponse.contractNbr
            });
        return of(promisePackage, promiseOffer);
      }
      return of(promisePackage);
    }),
    catchError(error => {
      return of(updatePackageAsync.failure(error));
    })
  );

export {
  fetchPackageAsync,
  fetchPackageEpic,
  fetchProductAsync,
  fetchProductEpic,
  fetchOfferAsync,
  fetchOfferEpic,
  updatePackageAsync,
  updatePackageEpic,
  resetUpdate
};
