import React from 'react';

import { InputEvent } from 'Types';
import { CustomInput, CustomButton } from '../../components';
import resources from '../../utils/resources';
import { isValidEmail } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.AuthPage;

type Props = {
  fields: IFields;
  error?: string;
  toggle: {
    passForgot: boolean;
  };
  handleToggle: any;
  handleInputChange(e: InputEvent, type: string): void;
  handleInputError(): boolean;
  handleButtonLogin(): void;
  handleButtonPassForgot(): void;
};

export interface IFields {
  login: string;
  password: string;
  email: string;
  errorLogin?: string;
  errorPassword?: string;
  errorEmail?: string;
  [key: string]: string | undefined;
}

const AuthContainer: React.FC<Props> = ({
  fields,
  toggle,
  error,
  handleInputChange,
  handleButtonLogin,
  handleButtonPassForgot,
  handleInputError,
  handleToggle
}) => {
  const isEmail = isValidEmail(fields.email);

  const renderForgotPassword = () => (
    <div className="auth-page__password-forgot">
      <h1>{Wording.passForgot.title}</h1>
      <h2>{Wording.passForgot.subTitle}</h2>

      <p>{Wording.passForgot.email}</p>
      <CustomInput
        value={fields.email}
        onInputChange={(e: InputEvent) => handleInputChange(e, 'email')}
      />

      {error && <p className="auth-page__password-forgot__error">{error}</p>}
      <div className="auth-page__password-forgot__buttons">
        <CustomButton
          title={Wording.cancelButton}
          color="gray"
          onClick={() => handleToggle()}
        />
        <CustomButton
          title={Wording.button}
          color={!isEmail ? 'gray' : 'orange'}
          onClick={() => {
            if (handleInputError()) {
              handleButtonPassForgot();
            }
          }}
        />
      </div>
    </div>
  );

  const renderConnexion = () => {
    const errorLoginPassword =
      fields.login.length < 6 || fields.password.length < 6;

    return (
      <div className="auth-page__connection">
        <h1>{Wording.title}</h1>
        <h6>{Wording.login}</h6>
        <CustomInput
          value={fields.login}
          error={fields.errorLogin}
          onInputChange={(e: InputEvent) => handleInputChange(e, 'login')}
        />
        <h6 className="pass">{Wording.pass}</h6>
        <CustomInput
          value={fields.password}
          error={fields.errorPassword}
          type="password"
          onInputChange={(e: InputEvent) => handleInputChange(e, 'password')}
        />
        <a onClick={() => handleToggle()} href="#">
          {Wording.passForgotLoginPage}
        </a>
        {error && <p>{error}</p>}

        <CustomButton
          title={Wording.button}
          color={errorLoginPassword ? 'gray' : 'orange'}
          onClick={() => {
            if (handleInputError()) {
              handleButtonLogin();
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className="auth-page">
      <img
        src={resources['ic-logo-login']}
        className="auth-page__logo"
        alt="logo-login"
      />
      <span className="auth-page__line" />

      {!toggle.passForgot ? renderConnexion() : renderForgotPassword()}
    </div>
  );
};

export default AuthContainer;
