import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { RootState } from 'Types';
import { SideBar, Footer } from '../components';
import routes, { RouteProps, SubRouteProps } from './routes';
import { resetReduxStore } from '../_actions/auth.actions';
import withLoader from '../libraries/withLoader';
import AuthService from '../services/auth-service';

const Content: React.FC<{ loading: boolean }> = ({ loading }) => (
  <div className="content" id="content">
    <Switch>
      {routes.map((route: RouteProps) =>
        route.links.map((subRoute: SubRouteProps, index: number) => (
          <Route
            key={index}
            path={route.path + subRoute.path}
            exact={subRoute.exact}
            component={subRoute.main}
          />
        ))
      )}
      <Redirect to="/" />
    </Switch>
  </div>
);

const WrappedContent = withLoader()(Content);

const mapStateToProps = (state: RootState) => ({
  authState: state.authReducer,
  profileState: state.profileReducer
});

const dispatchProps = {
  resetReduxStoreDispatch: resetReduxStore
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

class Main extends React.Component<Props, {}> {
  render() {
    const {
      profileState: { contact, customerNumber },
      authState: { loading, unauthorized },
      resetReduxStoreDispatch
    }: Props = this.props;

    const props = {
      firstName: contact.customerFullName.firstName,
      lastName: contact.customerFullName.lastName,
      customerNbr: customerNumber,
      handleLogout: () => resetReduxStoreDispatch()
    };
    const isSignIn = AuthService.isAuthenticated();
    if (!isSignIn) {
      return <Redirect to={{ pathname: '/login' }} />;
    }
    return (
      <div className="main-page">
        <div className="main-page__content">
          <SideBar {...props} />
          <WrappedContent
            loading={loading}
            unauthorized={unauthorized}
            handleCloseModal={() => {
              // this.props.history.push('')
              AuthService.logout();
              props.handleLogout();
            }}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(Main);
