import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { TEvent } from 'Models';

import * as actions from '../_actions/event.actions';

export type EventState = Readonly<{
  events: TEvent[];
  updateResponse: {
    code: string;
    message: string;
  };
}>;
export const initialState = {
  events: [],
  updateResponse: {
    code: '',
    message: ''
  }
};

type Actions = actions.EventAction;

const eventReducer: Reducer<EventState, Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getType(actions.fetchEventAsync.request):
      return { ...state };
    case getType(actions.fetchEventAsync.failure):
      return { ...state };
    case getType(actions.createEventAsync.request):
      return { ...state };

    case getType(actions.fetchEventAsync.success): {
      return {
        ...state,
        events: action.payload
      };
    }

    case getType(actions.createEventAsync.success): {
      return {
        ...state,
        events: [...state.events, action.payload],
        updateResponse: {
          code: '200',
          message: 'success'
        }
      };
    }
    case getType(actions.createEventAsync.failure):
      return {
        ...state,
        updateResponse: {
          code: '500',
          message: 'failure'
        }
      };

    case getType(actions.resetUpdate):
      return {
        ...state,
        updateResponse: {
          code: '',
          message: ''
        }
      };

    default:
      return { ...state };
  }
};

export default eventReducer;
