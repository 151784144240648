import IBAN from 'iban';
import { TContract, TThirdParty, TPackage, TWordingItem, TPiece } from 'Models';
import {
  EFrequency,
  ERateOption,
  EPaymentMode,
  ECivility,
  EBillingMode
} from './enums';

import WordingCommon from './wording.json';

const PackageWording = WordingCommon.PackagePage;

const isValidString = (value?: string): boolean => {
  return value !== undefined && value !== '';
};

const isValidEmail = (value?: string): boolean => {
  if (value === undefined) {
    return false;
  }
  const regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(value).toLowerCase());
};

const isValidPhone = (value?: string): boolean => {
  if (value && value.length === 10) {
    const sub = value.substr(0, 2);
    if (sub === '06' || sub === '07') {
      const regex = /^((06)|(07))[0-9]{8}$/;
      return regex.test(String(value));
    }
  }
  return false;
};

const isValidPassword = (value?: string): boolean => {
  if (value === undefined) {
    return false;
  }
  if (value.length < 6) {
    return false;
  }
  const regex = /[^\w\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))/;
  return regex.test(value);
};

const isValidIban = (value?: string): boolean => {
  if (value === undefined) {
    return false;
  }
  return IBAN.isValid(value);
};

const pad = (s: number) => {
  return s < 10 ? `0${s}` : s;
};

const isValidName = (value?: string): boolean => {
  if (value === undefined) {
    return false;
  }
  return value.indexOf(' ') >= 0;
};

const getFormattedDate = (date: string) => {
  const d = new Date(date);
  return d
    ? [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    : undefined;
};

const getFormattedDateToBackEnd = (d: Date) =>
  [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');

const getTodayDate = (): string => {
  const d = new Date();
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
};

const displayIban = (iban: string): string =>
  iban.toUpperCase().replace(/[^A-Z0-9]/g, '');

const displayNumberOnly = (value: string): string =>
  value.replace(/[^\d ]/g, '');

const displayPhoneWithoutPrefix = (value: string): string =>
  value.replace(/[^\d ][3]{2}/g, '0');

const getPhoneWithPrefix = (value: string) => value.replace(/^(0)/, '+33');

const findContractByNumber = (nbr: string, arr: TContract[]): TContract => {
  const object =
    arr.find((contract: TContract) => contract.contractNumber === nbr) ||
    arr[0];
  return object;
};

const findCoholder = (contract?: TContract): TThirdParty | undefined => {
  if (contract && contract.thirdParties) {
    return contract.thirdParties.find(
      (e: TThirdParty) => e.role === 'COHOLDER'
    );
  }
  return undefined;
};

const displayPlaceholder = (size: number): string => {
  const list = Array.from(Array(size).keys());
  let res = '';
  list.forEach(i => {
    res += `${i + 1}`;
  });
  return res;
};

const getYoutubeId = (link: string): string => {
  const s = link.split('v=');
  if (s.length < 1 || !s[1]) {
    return '';
  }
  let video_id = s[1];
  const ampersandPosition = video_id.indexOf('&');
  if (ampersandPosition !== -1) {
    video_id = video_id.substring(0, ampersandPosition);
  }
  return video_id;
};

const getTimeframeFromLabel = (label: string): string => {
  switch (label) {
    case 'Base':
      return 'TOTAL_HOUR';
    case 'Heure pleine':
      return 'HIGH';
    case 'Heure creuse':
      return 'LOW';
    default:
      return '';
  }
};

const getLabelByValue = (
  value: string,
  arr: Array<{
    value: string;
    label: string;
  }>
): string => {
  const objFind = arr.find(
    (obj: { value: string; label: string }) => obj.value === value
  ) || {
    value: '',
    label: ''
  };
  return objFind.label;
};

const transformPriceLabel = (label: string): string => {
  if (label === PackageWording.price.totalhour.label) {
    return PackageWording.price.totalhour.text;
  }
  if (label === PackageWording.price.high.label) {
    return PackageWording.price.high.text;
  }
  if (label === PackageWording.price.low.label) {
    return PackageWording.price.low.text;
  }
  if (label === PackageWording.price.paymentFrequency.label) {
    return PackageWording.price.paymentFrequency.text;
  }
  return label;
};

const displayFrequency = (frequency: EFrequency): string => {
  const freq = PackageWording.frequencies.find(f => f.value === frequency) || {
    text: ''
  };
  return freq.text;
};

const displayRateOption = (rateOption: ERateOption): string => {
  const f = PackageWording.rateOptions.find(r => r.value === rateOption) || {
    text: ''
  };
  return f.text;
};

const displayDateFromNumber = (d: {
  date: number;
  month: number;
  year: number;
}): string => {
  const { date, month, year } = d;
  const dayLabel = `${date < 10 ? `0${date}` : date}`;
  const monthLabel = `${month + 1 < 10 ? `0${month + 1}` : month + 1}`;
  return `${dayLabel}/${monthLabel}/${year}`;
};

const transformAuthorizedPackages = (packages?: TPackage[][]): number[][] => {
  if (packages === undefined) {
    return [[]];
  }
  const res = packages
    .map(p => {
      if (p && p.length === 2) {
        return p
          .sort((x: TPackage, y: TPackage) =>
            x.timeframe < y.timeframe ? -1 : 1
          )
          .map((x: TPackage) => parseInt(x.amount, 0));
      }
      return [0, 0];
    })
    .sort((x, y) => (x[0] < y[0] ? -1 : 1));
  return res;
};

const findPackagesByRange = (
  range: number[],
  packages?: TPackage[][]
): TPackage[] => {
  if (packages === undefined) {
    return [];
  }
  return (
    packages.find(
      (ps: TPackage[]) =>
        Number(ps[0].amount) === range[0] && Number(ps[1].amount) === range[1]
    ) || []
  );
};

const findPackageById = (
  packageID: string,
  packages?: TPackage[],
  recommended?: boolean
): TPackage | undefined => {
  if (packages === undefined) {
    return undefined;
  }
  const pkg = packages.find(p => p.id === packageID);
  if (pkg === undefined && recommended) {
    return packages.find(p => p.recommended);
  }
  return pkg;
};

const getLabelCivility = (civility: ECivility): string => {
  const value = civility as string;
  const res = WordingCommon.EnumItem.civilities.find(
    (c: TWordingItem) => c.value === value
  ) || { label: '' };
  return res.label;
};

const getLabelPaymentMode = (paymentMode: EPaymentMode): string => {
  const value = paymentMode as string;
  const res = WordingCommon.EnumItem.paymentModes.find(
    (c: TWordingItem) => c.value === value
  ) || { label: '' };
  return res.label;
};

const comparePieces = (a: TPiece, b: TPiece): number => {
  const x = new Date(a.pieceDueDate).getTime();
  const y = new Date(b.pieceDueDate).getTime();
  if (x < y) {
    return -1;
  }
  if (x > y) return 1;
  return 0;
};

const findNextAndLastPiece = (
  pieces?: TPiece[]
): { next: TPiece | undefined; last: TPiece | undefined } => {
  let next: TPiece | undefined;
  let last: TPiece | undefined;
  if (pieces === undefined) {
    return {
      next,
      last
    };
  }
  const sortedPieces = pieces.sort(comparePieces);
  const currentDate = new Date().getTime();
  sortedPieces.forEach((piece: TPiece) => {
    const piecesDate = new Date(piece.pieceDueDate).getTime();
    if (piecesDate > currentDate && next === undefined) {
      next = piece;
    }
    if (piecesDate < currentDate) {
      last = piece;
    }
  });

  return {
    next,
    last
  };
};

const findBillingModeCode = (cntt: any) => {
  return cntt.billingModeCode as EBillingMode;
};

export {
  findBillingModeCode,
  isValidPhone,
  isValidEmail,
  isValidPassword,
  isValidIban,
  isValidName,
  isValidString,
  findCoholder,
  getLabelCivility,
  getLabelPaymentMode,
  getFormattedDate,
  findContractByNumber,
  displayIban,
  displayNumberOnly,
  getTodayDate,
  displayPlaceholder,
  getYoutubeId,
  getTimeframeFromLabel,
  getLabelByValue,
  getFormattedDateToBackEnd,
  transformPriceLabel,
  displayFrequency,
  displayDateFromNumber,
  displayRateOption,
  transformAuthorizedPackages,
  findPackageById,
  findPackagesByRange,
  findNextAndLastPiece,
  displayPhoneWithoutPrefix,
  getPhoneWithPrefix
};
