import { throwError, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';

import Config from '../utils/config';

const apiRequest = <T>({
  path,
  method,
  body,
  isSubscription
}: any): Observable<T> => {
  const modifiedBody = {
    ...body,
    channel: 'WEB',
    seller: 'WEKIWI'
  };

  const settings = {
    method,
    url: `${isSubscription ? Config.URL_SUBSCRIPTION : Config.URL}${path}`,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    body: modifiedBody
    // crossDomain: true
    // withCredentials: true,
  } as any;

  const token = localStorage.getItem('token');
  if (token && path !== '/login') {
    const Authorization = 'Authorization';
    settings.headers[Authorization] = `Bearer ${token}`;
  }
  if (isSubscription) {
    settings.headers['x-api-key'] = Config.ApiKey;
  }
  const expires = Number(localStorage.getItem('expires'));
  if (expires && new Date().getTime() > expires) {
    return Observable.create((observer: any) => {
      observer.error({
        code: '401',
        message: 'Session expired'
      });
    });
  }

  console.log(`FO Data: POST ${path} ====> `, modifiedBody);
  return ajax(settings).pipe(
    catchError(({ message, status, response }) => {
      const error = {
        message,
        code: String(status)
      };
      if (response) {
        error.code = response.code;
        error.message = response.message;
      }
      console.log(`Error from API ${path} ====> `, error);
      return throwError(error);
    }),
    map(({ response }) => {
      console.log(`BO Response: POST ${path} ====> `, response);
      return response;
    })
  );
};

export const fetchApiRequestBlob = async (
  bodyRequest: any,
  path: string,
  configPath?: string
): Promise<Blob | undefined> => {
  const settings = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyRequest)
  } as any;

  const token = localStorage.getItem('token');
  if (token) {
    const Authorization = 'Authorization';
    settings.headers[Authorization] = `Bearer ${token}`;
  }

  console.log(`FO Data: POST ${path} ====> `, bodyRequest);
  try {
    const response = await fetch(
      `${configPath ? configPath : Config.URL}${path}`,
      settings
    );
    console.log(`BO Response: POST ${path} ====> `, response);
    return response.blob();
  } catch (err) {
    console.log(`Error from ${path} (fetch) =>`, err);
    return undefined;
  }
};

export default apiRequest;
