export enum EEnergy {
  EL = 'EL',
  NG = 'NG'
}

export enum ECustomerType {
  INDIVIDUAL = 'INDIVIDUAL',
  PROFESSIONAL = 'PROFESSIONAL'
}

export enum ECustomerStatus {
  PROSPECT = 'PROSPECT',
  CUSTOMER = 'CUSTOMER'
}

export enum ECustomerCategoryCode {
  CUSTOMERB2B = 'customerB2B',
  CUSTOMERB2C = 'customerB2C'
}

export enum EBillingMode {
  PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE',
  CYCLICAL_BILLING = 'CYCLICAL_BILLING'
}

export enum ECivility {
  MR = 'MR',
  MS = 'MS',
  MRS = 'MRS'
}

export enum EAppointmentTimeslot {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  DAY = 'DAY',
  NONE = 'NONE'
}

export enum EProcessType {
  MOVE_IN = 'MOVE_IN',
  SWITCH = 'SWITCH'
}

export enum EFrequency {
  BIMONTHLY = 'BIMONTHLY',
  QUARTERLY = 'QUARTERLY',
  FOURMONTHLY = 'FOURMONTHLY',
  BIANNUAL = 'BIANNUAL'
}

export enum EReadingFrequency {
  MONTHLY = 'MONTHLY',
  BIMONTHLY = 'BIMONTHLY',
  BIANNUAL = 'BIANNUAL',
  UNKNOWN = 'UNKNOWN'
}

export enum ETimeframe {
  TOTAL_HOUR = 'TOTAL_HOUR',
  LOW = 'LOW',
  HIGH = 'HIGH',
  WINTER = 'WINTER',
  SUMMER = 'SUMMER',
  UNKNOWN = 'UNKNOWN'
}

export enum ERateOption {
  TOTAL_HOUR = 'TOTAL_HOUR',
  HIGH_LOW = 'HIGH_LOW',
  UNKNOWN = 'UNKNOWN'
}

export enum EEffectiveStartRange {
  NOW = 'NOW',
  LATER = 'LATER'
}

export enum EInvoiceRoutingMode {
  EMAIL = 'EMAIL',
  MAIL = 'MAIL'
}

export enum EPaymentMode {
  CHECK = 'CHECK',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  BANK_TRANSFER = 'BANK_TRANSFER'
}

export enum EModeUpdateBilling {
  BILLING_ADDRESS = 'BILLING_ADDRESS',
  IBAN = 'IBAN'
}

export enum EModeManageOffer {
  INSTALLMENT_FREQUENCY = 'INSTALLEMENT_FREQUENCY',
  PRODUCT = 'PRODUCT',
  PACKAGE = 'PACKAGE',
  OPTIONS = 'OPTIONS'
}

export enum EAmountCategory {
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
  ADDITIONAL_RATE = 'ADDITIONAL_RATE',
  TOTAL_BUDGET = 'TOTAL_BUDGET',
  ECONOMY = 'ECONOMY'
}

export enum EAmountType {
  FEE = 'FEE',
  OPTION = 'OPTION',
  DISCOUNT = 'DISCOUNT'
}

export enum EContractStatus {
  INTERNAL_PROCESSING = 'INTERNAL_PROCESSING',
  WAITING = 'WAITING',
  CANCELLED = 'CANCELLED',
  ABONDONED = 'ABONDONED',
  REJECTED = 'REJECTED',
  SENT_TO_MARKET = 'SENT_TO_MARKET',
  EFFECTIVE = 'EFFECTIVE',
  TERMINATED = 'TERMINATED'
}
