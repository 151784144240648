import IconLogoSideBar from '../assets/icons/ic-logo-login.svg';
import IconLogoLogin from '../assets/icons/ic-logo-login.svg';
import IconElecOff from '../assets/icons/ic-elec-off.svg';
import IconElecOn from '../assets/icons/ic-elec-on.svg';
import IconElecGreen from '../assets/icons/ic-elec-green.svg';
import IconGasOff from '../assets/icons/ic-gas-off.svg';
import IconGasOn from '../assets/icons/ic-gas-on.svg';
import IconLogOut from '../assets/icons/ic-logout-sidebar.svg';
import IconAvatar from '../assets/icons/ic-avatar.svg';
import IconLinkedin from '../assets/icons/ic-linkedin.svg';
import IconInstagram from '../assets/icons/ic-instagram.svg';
import IconFacebook from '../assets/icons/ic-facebook.svg';
import IconYoutube from '../assets/icons/ic-youtube.svg';
import IconTwitter from '../assets/icons/ic-twitter.svg';
import IconCertification from '../assets/icons/ic-certification.svg';

export default {
  'ic-logo-sidebar': IconLogoSideBar,
  'ic-logo-login': IconLogoLogin,
  'ic-elec-off': IconElecOff,
  'ic-elec-on': IconElecOn,
  'ic-elec-green': IconElecGreen,
  'ic-gas-on': IconGasOn,
  'ic-gas-off': IconGasOff,
  'ic-logout-sidebar': IconLogOut,
  'ic-avatar': IconAvatar,
  'ic-linkedin': IconLinkedin,
  'ic-instagram': IconInstagram,
  'ic-facebook': IconFacebook,
  'ic-youtube': IconYoutube,
  'ic-twitter': IconTwitter,
  'ic-certification': IconCertification
};
