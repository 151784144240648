import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { TPiece } from 'Models';

import * as actions from '../_actions/payment.actions';

export type PaymentState = Readonly<{
  pieces: TPiece[];
  updateResponse: {
    code: string;
    message: string;
  };
  balance?: {
    customerBalance: string;
    contractBalance: string;
    invoiceBalance: string;
  };
}>;
export const initialState = {
  pieces: [],
  updateResponse: {
    code: '',
    message: ''
  },
  balance: undefined
};

type Actions = actions.PiecesAction;

const paymentReducer: Reducer<PaymentState, Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getType(actions.fetchPiecesAsync.success): {
      return { ...state, pieces: action.payload || [] };
    }
    case getType(actions.fetchBalanceAsync.success): {
      return { ...state, balance: action.payload };
    }
    case getType(actions.createPaymentAsync.success):
      return {
        ...state,
        updateResponse: {
          code: '200',
          message: 'success'
        }
      };
    case getType(actions.createPaymentAsync.failure):
      return {
        ...state,
        updateResponse: {
          code: '500',
          message: 'failure'
        }
      };
    case getType(actions.resetUpdate):
      return {
        ...state,
        updateResponse: {
          code: '',
          message: ''
        }
      };

    default:
      return { ...state };
  }
};

export default paymentReducer;
