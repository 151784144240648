import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Location } from 'history';
import { InputEvent, RootState } from 'Types';

import withLoader from '../../libraries/withLoader';
import AuthContainer, {
  IFields
} from '../../containers/AuthContainer/AuthContainer';

import {
  fetchLoginAsync,
  resetPasswordAsync
} from '../../_actions/auth.actions';

import { isValidEmail } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';
import AuthService from '../../services/auth-service';
const Wording = WordingConstant.AuthPage;

const mapStateToProps = (state: RootState) => ({
  authState: state.authReducer
});

const dispatchProps = {
  authDispatch: fetchLoginAsync.request,
  resetPasswordDispatch: resetPasswordAsync.request
};

type InjectedProps = {
  location: Location;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  InjectedProps;

type State = {
  fields: IFields;
  toggle: {
    passForgot: boolean;
  };
};

export const WrappedAuth = withLoader()(AuthContainer);

export class AuthPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: {
        login: '',
        password: '',
        email: '',
        errorLogin: undefined,
        errorPassword: undefined,
        errorEmail: undefined
      },
      toggle: {
        passForgot: false
      }
    };
  }

  handleChange = (e: InputEvent, type: string): void => {
    const { value } = e.currentTarget;
    const { fields } = this.state;
    fields[type] = value;
    this.setState({ fields });
  };

  handleToggle = () => {
    const { toggle } = this.state;
    toggle.passForgot = !toggle.passForgot;
    this.setState({ toggle });
  };

  handleButtonPassForgot = () => {
    const {
      fields: { email }
    } = this.state;
    const { resetPasswordDispatch } = this.props;
    resetPasswordDispatch({
      email
    });
  };

  handleButtonLogin = () => {
    const { fields } = this.state;
    const { authDispatch } = this.props;
    authDispatch({
      user: fields.login,
      password: fields.password
    });
  };

  handleInputError = (): boolean => {
    const {
      fields,
      toggle: { passForgot }
    } = this.state;

    if (passForgot) {
      const isEmail = isValidEmail(fields.email);
      fields.errorEmail = isEmail ? Wording.error.notEmail : undefined;
      this.setState({ fields });
      return isEmail;
    }
    fields.errorLogin =
      fields.login.length < 6 ? Wording.error.notLogin : undefined;
    fields.errorPassword =
      fields.password.length < 6 ? Wording.error.notPassword : undefined;
    this.setState({ fields });
    return fields.login.length >= 6 && fields.password.length >= 6;
  };

  render() {
    const { fields, toggle } = this.state;
    const {
      location,
      authState: { loading, error }
    }: Props = this.props;
    const { from } = location.state || { from: { pathname: '/' } };
    const isSignIn = AuthService.isAuthenticated();
    // if (isSignIn) return <Redirect to={from} />;
    // Always redirect to dashboard if login succeess
    if (isSignIn) return <Redirect to={{ pathname: '/' }} />;

    const sendError =
      error && error.search('message') !== -1
        ? JSON.parse(error).message
        : error;

    return (
      <WrappedAuth
        loading={loading}
        unauthorized={false}
        handleCloseModal={() => {}}
        error={sendError}
        toggle={toggle}
        fields={fields}
        handleInputChange={this.handleChange}
        handleToggle={this.handleToggle}
        handleButtonLogin={this.handleButtonLogin}
        handleButtonPassForgot={this.handleButtonPassForgot}
        handleInputError={this.handleInputError}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  dispatchProps
)(AuthPage);
