import React, { useState } from 'react';

import {
  TContract,
  TProduct,
  TOffer,
  TPackage,
  TPrepaymentFrequency,
  TAdditionalRate
} from 'Models';
import {
  EEnergy,
  EFrequency,
  EContractStatus,
  EAmountType,
  EBillingMode
} from '../../utils/enums';
import { IPayloadUpdatePackage as PackagePayload } from '../../_actions/package.actions';
import { PlanElement, EnergyDetail, OptionElement } from '../../components';
import PackageSettingContainer from './PackageSettingContainer';

import {
  getFormattedDate,
  displayRateOption,
  displayFrequency,
  transformAuthorizedPackages,
  findBillingModeCode
} from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PackagePage;

export type Props = {
  loadingPackage: boolean;
  loadingOffer: boolean;
  contract: TContract;
  packages?: TPackage[];
  products: TProduct[];
  offer: TOffer;
  prepaymentFrequencies: TPrepaymentFrequency[];
  handleFetchPackage(): void;
  handleSetPayload(payload: PackagePayload): void;
  handleShowModal(section: string): void;
};

const PackageContainer: React.FC<Props> = ({
  loadingPackage,
  loadingOffer,
  contract,
  products,
  offer,
  packages,
  prepaymentFrequencies,
  handleFetchPackage,
  handleSetPayload,
  handleShowModal
}) => {
  const [showOption, setShowOption] = useState<boolean>(true);
  const options: TAdditionalRate[] = contract.chosenProduct.additionalRates
    ? contract.chosenProduct.additionalRates.filter(
        r => r.type === EAmountType.OPTION
      )
    : [];
  const currentAdditionalOptions: string[] = options
    .filter(r => r.active)
    .map(r => r.label);

  const currentProductCodes: string[] = products
    .filter(p => p.productCode === contract.chosenProduct.productCode)
    .map(p => p.productCode);

  const chosenPackages = contract.chosenPackages;
  const packageID: string =
    chosenPackages && chosenPackages.length === 1 ? chosenPackages[0].id : '';
  const prices: number[] = chosenPackages
    ? chosenPackages.map(p => Number(p.amount))
    : [];
  let ranges: number[][] = [[]];
  if (chosenPackages && chosenPackages.length === 1) {
    ranges = transformAuthorizedPackages(chosenPackages[0].authorizedPackages);
  } else {
    if (packages) {
      const recommendPackage = packages.find(p => p.recommended);
      ranges = recommendPackage
        ? transformAuthorizedPackages(recommendPackage.authorizedPackages)
        : [[]];
    }
  }

  const current = {
    packageID,
    prices,
    frequency: contract.installmentFrequency as EFrequency
  };

  const renderDetail = () => {
    const deliveryPoint = contract.deliveryPoint;
    const details = [];
    let separateDetails = [];
    if (contract.energy === EEnergy.EL) {
      details.push({
        title: Wording.detailDescription.PDL,
        value: deliveryPoint.pointOfDelivery
      });
    } else {
      details.push({
        title: Wording.detailDescription.PCE,
        value: deliveryPoint.pointOfDelivery
      });
    }
    details.push({
      title: Wording.detailDescription.beginDate,
      value: getFormattedDate(contract.effectiveStartDate) || ''
    });
    if (contract.energy === EEnergy.EL) {
      const rateOption = displayRateOption(contract.rateOption);
      separateDetails = [
        {
          title: Wording.detailDescription.contractualCapacityEL,
          value: `${deliveryPoint.contractualCapacity} ${
            deliveryPoint.contractualCapacityUnit
          }`
        },
        {
          title: Wording.detailDescription.meterReading,
          value: deliveryPoint.meterNumber
        },
        {
          title: Wording.detailDescription.priceOption,
          value: rateOption
        }
      ];
    } else {
      separateDetails = [
        {
          title: Wording.detailDescription.profile,
          value: deliveryPoint.profile
        },
        {
          title: Wording.detailDescription.meterReading,
          value: deliveryPoint.meterNumber
        },
        {
          title: Wording.detailDescription.priceOption,
          value: deliveryPoint.gridRate
        }
      ];
      if (contract.estimates && contract.estimates.length > 0) {
        separateDetails.unshift({
          title: Wording.detailDescription.contractualCapacityNG,
          value: `${contract.estimates[0].quantity} ${
            Wording.detailDescription.contractualCapacityUnitNG
          }`
        });
      }
    }
    const readingFrequency = displayFrequency(
      deliveryPoint.readingFrequency as EFrequency
    );
    const installmentFrequency = displayFrequency(
      contract.installmentFrequency as EFrequency
    );
    const settlementFrequency = displayFrequency(
      contract.settlementFrequency as EFrequency
    );

    details.push(
      ...separateDetails,
      ...[
        {
          title: Wording.detailDescription.readingFrequency,
          value: readingFrequency
        },
        {
          title: Wording.detailDescription.installmentFrequency,
          value: installmentFrequency
        },
        {
          title: Wording.detailDescription.settlementFrequency,
          value: settlementFrequency
        }
      ]
    );

    return <EnergyDetail details={details} />;
  };

  const handleValidate = (section: string, payload: PackagePayload) => {
    handleShowModal(section);
    handleSetPayload(payload);
  };

  /**
   * TODO: uncomment the block to reuse the contractStatus
   * If the contract is not yet activated, should only display messages
   */
  return (
    <div className="package-container">
      {/* {contract.contractStatus !== EContractStatus.EFFECTIVE ? (
                <div className="package-container__contractStatus">
                    <p>
                        {contract.contractStatus ===
                            EContractStatus.INTERNAL_PROCESSING ||
                        contract.contractStatus ===
                            EContractStatus.SENT_TO_MARKET
                            ? Wording.contractStatus.inProgress
                            : Wording.contractStatus.notAccessible}
                    </p>
                </div>
            ) : ( */}
      <>
        <h1 className="package-container__subtitle">{Wording.subtitle}</h1>

        <div className="package-container__body">
          <PlanElement
            loading={loadingOffer}
            prices={prices}
            offer={offer}
            products={products}
            additionalOptions={options}
            currentProductCodes={currentProductCodes}
            currentAdditionalOptions={currentAdditionalOptions}
            loadingOption={false}
            handleToggle={handleValidate}
          />

          <div className="package-container__body__right">
            {renderDetail()}
            {showOption && options && options.length > 0 && (
              <OptionElement
                idToggle={contract.energy}
                options={options}
                currentOptions={currentAdditionalOptions}
                handleToggle={handleValidate}
              />
            )}
            {findBillingModeCode(contract) !==
              EBillingMode.CYCLICAL_BILLING && (
              <PackageSettingContainer
                current={current}
                loading={loadingPackage}
                packages={packages}
                ranges={ranges}
                prepaymentFrequencies={prepaymentFrequencies}
                handleSetShowOption={(value: boolean) => {
                  setShowOption(value);
                }}
                handleFetchPackage={handleFetchPackage}
                handleValidate={handleValidate}
              />
            )}
          </div>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default PackageContainer;
