import React from 'react';
import Select from 'react-select';

export interface IStateElements {
  isMenuOpen: boolean;
}

interface IProps {
  id?: string;
  text?: string;
  value?: any;
  defaultValue?: any;
  options?: any[];
  placeholder?: string;
  handleChangeSelect: any;
  error?: string | undefined;
}

class CustomSelect extends React.Component<IProps> {
  state: IStateElements = {
    isMenuOpen: false
  };

  handleChange(val: boolean): void {
    this.setState({ isMenuOpen: val });
  }

  render() {
    const {
      id,
      text,
      value,
      defaultValue,
      handleChangeSelect,
      options,
      placeholder,
      error
    } = this.props;

    const current =
      options && options.find(option => option.value === defaultValue);
    const { isMenuOpen } = this.state;
    return (
      <div className="custom-select">
        <div className="custom-select__content">
          <label htmlFor={id}>{text}</label>
          <Select
            className={`custom-select__content__select ${
              isMenuOpen ? 'custom-select__content__select--menuOpen' : ''
            }`}
            classNamePrefix="react-select"
            value={value}
            placeholder={placeholder || (current || {}).label}
            defaultValue={defaultValue}
            onChange={handleChangeSelect}
            options={options}
            onMenuOpen={() => this.handleChange(true)}
            onMenuClose={() => this.handleChange(false)}
          />
        </div>

        {error && error !== '' && (
          <p className="custom-select__error">{error}</p>
        )}
      </div>
    );
  }
}

export default CustomSelect;
