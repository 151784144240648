import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
  RouteComponentProps
} from 'react-router-dom';
import store from './store';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { version } from '../package.json';

import AuthService from './services/auth-service';
import AuthPage from './pages/AuthPage';
import ConnectedMain from './pages/Main';

interface IPrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isSignIn = AuthService.isAuthenticated();
  return (
    <Route
      {...rest}
      render={props =>
        isSignIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/login" component={AuthPage} />
        <PrivateRoute path="/" component={ConnectedMain} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

console.log('Version : ', version);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
